import styled from "@emotion/styled";
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Table } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "../../redux/Store";
import { addSection, getSection, startTest } from "../../redux/slices/Test";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Ellipse } from "../iconsJS/Ellipse";
import { IconStartButton } from "../iconsJS/IconStartButton";
import PrimaryButton from "../primary-button/PrimaryButton";
import Typo from "../typo/Typo";
import { useStyles } from "./Style";
import { Container } from "@mui/material";
import { TickSvg } from "../iconsJS/Tick";
import "./TestSection.css";
import CustomizedSnackbars from "../snack/snack";
import useSnack from "../../hooks/useSnack";
import { useTheme } from "@material-ui/core";
import { ContentWrapper, Wrapper } from "../../assets/styles/CommonStyles";
import { useParams } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
const Tablemin = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    paddingRight: 20,
  },
}));
const Minty = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    marginLeft: -10,
  },
}));
const Tablet = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    width: "100%",
    // background:"red",
  },
}));

const Sections = [
  {
    Section: "Section 1",
    Questions: "20",
    Durations: "40 minutes",
  },
  {
    Section: "Section 2",
    Questions: "12",
    Durations: "24 minutes",
  },
  {
    Section: "Section 3",
    Questions: "30",
    Durations: "60 minutes",
  },
  {
    Section: "Section 4",
    Questions: "10",
    Durations: "20 minutes",
  },
];

const Responsive = styled("Divider")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: { orientation: "horizontal" },
}));

export const ChemistryTest = (props) => {
  const params = useParams();
  console.log(params);
  const { state } = useLocation();
  console.log(state?.isPage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questionDetails, error } = useSelector((state) => state.test);
  const [sectionData, setSectionData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [index, setIndex] = useState(0);
  const [selectedChoice, setSelectedChoices] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const { open, setOpen, handleClose, message, setMessage, color, setColor } =
    useSnack();
  const [isPageSection, setIsPageSection] = useState(true);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    dispatch(
      getSection((response) => {
        setSectionData(response.data);
        if (response?.data?.sections.find((item) => item.status === "TODO")) {
          setSelectedChoices(
            response?.data?.sections[
              response?.data?.sections.findIndex(
                (item) => item.status === "TODO"
              )
            ].id
          );
          const pageValue = response.data?.sections;
          const filterValue = pageValue.find(
            (item) => item.status === "TODO" || item.status === "INPROGRESS"
          );
          setFilterStatus(filterValue.status);
          if (filterValue?.status === "TODO" || "INPROGRESS") {
            setIsPageSection(true);
          } else {
            setIsPageSection(false);
          }
          setIndex(
            response?.data?.sections.findIndex((item) => item.status === "TODO")
          );
          // dispatch(addSection(response.data.sections[index].id), (res) => {});
        }
      })
    );
  }, []);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ mediaQuery });

  const handleStartTest = () => {
    dispatch(
      startTest(state?.isPage === false ? false : isPageSection, (response) => {
        console.log(response.data, "dddd");
        if (response?.data?.id) {
          navigate("/QuestionAns");
        } else if (response.message === "Test attempt exceeded") {
          console.log(sectionData);
          console.log(sectionData?.testTitle);
          navigate(`/Confirmation/${sectionData?.testName}/${sectionData?.id}
          `);
          setOpen(true);
          setMessage(response.message);
          setColor("error");
        } else if (response.message === "Test in progress") {
          console.log("test");
          navigate("/QuestionAns");
        } else if (response.message === "Test completed") {
          navigate(`/Confirmation/${sectionData?.testName}/${sectionData?.id}
          `);

          console.log("sneha");
          setOpen(true);
          setMessage(response.message);
          setColor("error");
        } else if (response.message === "Test ended") {
          setOpen(true);
          setMessage(response.message);
          setColor("error");
        } else {
          setOpen(true);
          setMessage(response);
          setColor("error");
        }
      })
    );
  };

  const setChoice = (e) => {
    setSelectedChoices(e.target.value);
    if (
      sectionData?.sections.findIndex((item) => item.id === e.target.value) !==
      -1
    ) {
      setIndex(
        sectionData?.sections.findIndex((item) => item.id === e.target.value)
      );
      dispatch(addSection(e.target.value), (res) => {});
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <div className={classes.sectionShadow}>
          <Container maxWidth="xl" className={classes.testHeader}>
            <Grid container>
              <div className={classes.testHead}>
                <Grid item md={12} xs={12}>
                  <Typo
                    variant="h4"
                    fontWeight={600}
                    color="#354354"
                    className={classes.testHeaderSection}
                  >
                    {sectionData?.testName}
                  </Typo>
                </Grid>
                <br></br>
                <Grid item md={12} xs={12}>
                  <Typo variant="body1" color=" #5B656F">
                    {sectionData?.sections[index]?.nameDescription}
                  </Typo>
                </Grid>
              </div>
            </Grid>
            <br></br>

            {/* <Container maxWidth="500" > */}
            <Grid
              container
              //  padding={3}
            >
              <Grid item md={5} xs={12}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Typo variant="h5" fontWeight={600} color=" #354354">
                      Ready to start?
                    </Typo>
                  </Grid>
                  <Grid item md={12} xs={12} marginTop={"10px"}>
                    <Typo color="#5B656F" variant="body1">
                      Select the section you would like to attempt first and
                      then click on start test button.
                    </Typo>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} marginTop={"10px"}>
                  <Grid item md={12} xs={12} style={{ marginBottom: "10px" }}>
                    <div className={classes.sectionstyle}>
                      <div style={{ width: mediaQuery ? "30%" : "60%" }}>
                        <Typo variant="body1" fontWeight="700" color="#354354">
                          Section Name
                        </Typo>
                      </div>
                      <div
                        className={classes.Sectiongap}
                        // style={{
                        //   width: "40%",

                        // }}
                      >
                        <Typo
                          variant="body1"
                          fontWeight="700"
                          color="#354354"
                          // variant="body1"
                          // fontWeight="700"
                          // color="#354354"
                          // align="center"
                        >
                          No of Questions
                        </Typo>
                      </div>
                      <div>
                        <Typo variant="body1" fontWeight="700" color="#354354">
                          Duration
                        </Typo>
                      </div>
                    </div>
                  </Grid>
                  <RadioGroup onChange={setChoice}>
                    {sectionData?.sections?.map((Section) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              backgroundColor:
                                selectedChoice === Section.id
                                  ? "#F4F5F5"
                                  : "white",
                              // borderRadius: "6px",
                              boxShadow: "rgb(0 0 0 / 10%) 0px 4px 12px",
                            }}
                          >
                            <div
                              style={{
                                // maxWidth: mediaQuery ? "30%" : "60%",
                                width: "60%",
                                flexDirection: "row",
                                display: "flex",
                                alignContent: "center",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              <div
                                // align="right"
                                style={{
                                  borderBottom: "none",
                                }}
                              >
                                <FormControlLabel
                                  value={Section.id}
                                  // id={Section.id}
                                  control={
                                    Section.status === "TODO" ? (
                                      <Radio
                                        style={{ color: "#4065db" }}
                                        checked={selectedChoice === Section.id}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: 10,
                                        }}
                                      >
                                        {" "}
                                        <TickSvg />
                                        &nbsp;&nbsp;
                                      </div>
                                    )
                                  }
                                  className="SectionColor"
                                  label={Section?.name}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                color: "5B656F",
                                width: "43%",
                              }}
                            >
                              <Typo align="left">{Section?.noOfQuestion}</Typo>
                            </div>
                            <div
                              style={{
                                borderBottom: "none",
                                width: "35%",
                                fontWeight: "400",
                              }}
                            >
                              <Typo
                                id="duration"
                                color="5B656F"
                                align="center"
                                fontWeight="400"
                              >
                                {Math.round(Section?.duration)} Minutes
                              </Typo>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </RadioGroup>
                </Grid>

                <Grid item md={12} xs={12} paddingTop={1}></Grid>
                {/* </Grid> */}
              </Grid>
              <Grid
                item
                md={1}
                xs={12}
                justifyContent="center"
                style={{ display: width < 700 ? "unset" : "flex" }}
              >
                <Divider
                  orientation={width < 700 ? "horizontal" : "vertical"}
                  flexItem
                  style={{ height: width < 700 ? 20 : 400 }}
                ></Divider>
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                id="scrollable"
                style={{ overflow: "scroll" }}
              >
                <Grid container id="scrolllable">
                  <Grid item md={12} xs={12}>
                    <Typo
                      fontSize={24}
                      fontWeight={600}
                      color=" #354354"
                      paddingTop="1rem"
                    >
                      Section {index + 1}: {sectionData?.sections[index]?.name}
                    </Typo>
                  </Grid>

                  <Grid item md={12}>
                    <div className={classes.CardContainer}>
                      <Card className={classes.CardStyle}>
                        <Grid container>
                          <Grid item md={3} xs={3}>
                            <Typo
                              variant="body1"
                              color="#5B656F"
                              fontWeight="400"
                            >
                              Number of <br></br>Questions:
                            </Typo>
                          </Grid>
                          <Grid item md={2} xs={2}>
                            <Typo
                              className={classes.testNumberAlign}
                              variant="h4"
                              style={{
                                fontWeight: 600,
                                color: "#5B656F",
                              }}
                            >
                              {sectionData?.sections[index]?.noOfQuestion}
                            </Typo>
                          </Grid>

                          <Grid item md={1} xs={1}>
                            <div
                              style={{
                                // borderLeft: "1px Solid",
                                // flexGrow: 1,
                                height: "100%",
                                width: "1px",
                                backgroundColor: "#C4C4C4",
                              }}
                            ></div>
                          </Grid>

                          <Grid
                            item
                            md={4}
                            xs={3}
                            id="duration"
                            style={{
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <Typo
                              variant="body1"
                              color="#5B656F"
                              fontWeight="400"
                            >
                              Test <br></br>Duration:
                            </Typo>
                            <Typo
                              className={classes.testNumberAlign}
                              variant="h4"
                              style={{
                                fontWeight: 600,
                                marginLeft: "10px",
                                color: "#5B656F",
                              }}
                            >
                              {Math.round(
                                sectionData?.sections[index]?.duration
                              )}
                            </Typo>
                            {mediaQuery ? (
                              <Typo
                                variant="h4"
                                style={{
                                  fontWeight: 400,
                                  marginLeft: "10px",
                                  color: "#354354",
                                }}
                              >
                                Minutes
                              </Typo>
                            ) : (
                              <Typo
                                variant="h5"
                                style={{
                                  color: "#354354",
                                  fontWeight: 600,
                                  marginLeft: "10px",
                                }}
                              >
                                Min
                              </Typo>
                            )}
                          </Grid>
                        </Grid>
                      </Card>

                      <div>
                        <Grid container style={{ paddingLeft: "2%" }}>
                          <Grid item xs={12}>
                            <Typo
                              variant="h6"
                              fontWeight={600}
                              color={"#354354"}
                              paddingTop={5}
                            >
                              Test Instruction
                            </Typo>
                          </Grid>

                          <Grid item xs={12}>
                            <Typo
                              paddingTop={2}
                              paddingBottom={2}
                              variant="body1"
                              color="#5B656F"
                              fontWeight="400"
                            >
                              {sectionData?.descriptionTitle}
                              {/* {sectionData?.sections[index]?.nameDescription} */}
                            </Typo>
                          </Grid>

                          <Grid
                            item
                            xs={12}

                            // style={{
                            //   position: "relative",
                            //   height: "250px",
                            //   overflowY: "scroll",
                            //   marginBottom: "2%",
                            //   textAlign: "justify",
                            // }}
                          >
                            {sectionData?.sections[index]?.description.map(
                              (item, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "8px",
                                      // alignItems: "center",
                                    }}
                                  >
                                    <b>
                                      {" "}
                                      <Ellipse
                                        style={{ alignItems: "center" }}
                                      />
                                    </b>
                                    <Typo
                                      variant="body1"
                                      color="#5B656F"
                                      fontWeight="400"
                                    >
                                      {item}
                                      <br />
                                      <br></br>
                                    </Typo>
                                  </div>
                                );
                              }
                            )}
                          </Grid>

                          <Grid
                            item
                            md={12}
                            xs={12}
                            style={{
                              display: "flex",
                              width: 100,
                              // justifyContent: "center",
                              padding: 10,
                            }}
                          >
                            {mediaQuery && (
                              <Box className={classes.staticButtonContainer}>
                                <Box className={"static__button_wrapper"}>
                                  <Box className={classes.buttonWrapper1}>
                                    <PrimaryButton
                                      fullWidth={!mediaQuery ? true : false}
                                      width={!mediaQuery ? "100%" : "220px"}
                                      onClick={() => handleStartTest()}
                                    >
                                      <b
                                        className={classes.teststyle}
                                        // style={{
                                        //   position: "relative",
                                        //   left: "20px",
                                        // }}
                                      >
                                        {" "}
                                        START TEST
                                      </b>
                                      <IconStartButton />
                                    </PrimaryButton>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} xs={12}></Grid>
              <Divider orientation="horizontal"></Divider>
            </Grid>
          </Container>
          <CustomizedSnackbars
            open={open}
            handleClose={handleClose}
            text={message}
            type={color}
          />
        </div>
      </ContentWrapper>
      {!mediaQuery && (
        <Box className={classes.staticButtonContainer}>
          <Box className={"static__button_wrapper"}>
            <Box className={classes.buttonWrapper}>
              <PrimaryButton
                fullWidth={!mediaQuery ? true : false}
                width={!mediaQuery ? "100%" : "220px"}
                onClick={() => handleStartTest()}
              >
                <b
                  className={classes.testStart}
                  // style={{ position: "relative", left: "20px" }}
                >
                  {" "}
                  START TEST
                </b>
                <IconStartButton />
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};
