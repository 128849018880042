import { Grid } from "@mui/material";
import { isPoneNumber } from "../../utils/Validation";
import DatePicker from "../common-components/DatePicker";
import DropDown from "../common-components/DropDown";
import InputField from "../common-components/InputField";
import { CustomTypo, InputBox, useStyles, ErrorMsg } from "./Styles";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const EXPERIENCE_STATUS = [
  {
    value: "Fresher",
    label: "Fresher",
  },
  {
    value: "Experienced",
    label: "Experienced",
  },
];

const EDUCATION_GAP = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const GENDER = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Others",
    label: "Others",
  },
];

function PersonalInfo({
  studentName,
  handleChange,
  gender,
  dropDownValue,
  educationGap,
  EducationDropDown,
  phoneNumber,
  dateOfBirth,
  handleChangeDateOfBirth,
  getFormDetails,
  errorMsg,
  email,
  FresherOrExperienced,
  FresherOrExperiencedDropDown,
  allState,
}) {
  const classes = useStyles();
  console.log(
    allState,
    "allState",
    new Date(allState?.dateOfBirth)?.getFullYear(),
    "kathuir",
    allState?.dateOfBirth,
    new Date(allState?.tenthPassingYear).getFullYear(),
    "ojlioh",
    errorMsg
  );
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <CustomTypo variant={"body2"} className={classes.sectionText}>
          {"Section-1"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <CustomTypo variant={"h6"} color={"#354354"} fontWeight={600}>
          {"Personal Information"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2.75} pb={2.75}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Name "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    value={studentName}
                    autoComplete="off"
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter Name"}
                    name="studentName"
                    disabled={getFormDetails?.name ? true : false}
                    hasNoBorder
                  />
                </InputBox>
                <ErrorMsg>{errorMsg.name}</ErrorMsg>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Date of birth "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={dateOfBirth}
                      name={"dateOfBirth"}
                      views={["year", "month", "day"]}
                      onChange={(e) => handleChangeDateOfBirth(e)}
                      disabled={getFormDetails?.dob ? true : false}
                      renderInput={(params) => (
                        <InputField
                          hasNoBorder
                          placeholder={"Enter date of birth"}
                          {...params}
                        />
                      )}
                      disableFuture
                    />
                  </LocalizationProvider>
                </InputBox>
                {allState?.dateOfBirth === null &&
                  allState?.tenthPassingYear === null ? (
                  <ErrorMsg>{errorMsg?.dob}</ErrorMsg>
                ) : allState?.dateOfBirth === null ||
                  allState?.tenthPassingYear === null ? null : new Date(
                    allState?.tenthPassingYear
                  )?.getFullYear() -
                    new Date(allState?.dateOfBirth)?.getFullYear() <
                    12 ? (
                  <ErrorMsg>{errorMsg?.dob}</ErrorMsg>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Gender "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={GENDER || []}
                    getOptionLabel={(item) => item.value}
                    placeholder={"Select gender"}
                    hasNoBorder
                    value={gender}
                    name={"gender"}
                    disabled={getFormDetails?.gender ? true : false}
                    onChange={(e, obj) => dropDownValue(e, obj)}
                  />
                </InputBox>
                {gender?.value ? null : <ErrorMsg>{errorMsg.gender}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Any gap in education? "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    value={educationGap}
                    onChange={(e, obj) => EducationDropDown(e, obj)}
                    name={"educationGap"}
                    options={EDUCATION_GAP || []}
                    disabled={getFormDetails?.educationGapStr ? true : false}
                    getOptionLabel={(item) => item.value}
                    placeholder={"Select gap in education"}
                    hasNoBorder
                  />
                </InputBox>
                {Boolean(educationGap?.value) ? null : (
                  <ErrorMsg>{errorMsg.educationGap}</ErrorMsg>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Fresher or Experienced? "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={EXPERIENCE_STATUS || []}
                    getOptionLabel={(item) => item.value}
                    onChange={(e, obj) => FresherOrExperiencedDropDown(e, obj)}
                    value={FresherOrExperienced}
                    disabled={getFormDetails?.experienceStatus ? true : false}
                    placeholder={"Select fresher/experienced"}
                    hasNoBorder
                  />
                </InputBox>
                {Boolean(FresherOrExperienced?.value) ? null : (
                  <ErrorMsg>{errorMsg.FresherOrExperienced}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Email ID "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={"email"}
                    autoComplete="off"
                    placeholder={"Enter email id"}
                    hasNoBorder
                    value={email}
                    disabled={getFormDetails?.emailId ? true : false}
                    onChange={(e) => handleChange(e)}
                    name="email"
                  />
                </InputBox>
                <ErrorMsg>{errorMsg.email}</ErrorMsg>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Phone number "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={"number"}
                    name={"phoneNumber"}
                    autoComplete="off"
                    placeholder={"Enter phone number"}
                    onKeyPress={(evt) => {
                      if (isPoneNumber(evt)) evt.preventDefault();
                    }}
                    value={phoneNumber}
                    disabled={getFormDetails?.phoneNumber ? true : false}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ min: 0 }}
                    hasNoBorder
                  />
                </InputBox>
                <ErrorMsg>{errorMsg.phoneNumber}</ErrorMsg>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalInfo;