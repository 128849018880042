import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  CardContainer: {
    display: "flex",
    background: "#FFFFFF",
    paddingLeft: "10px",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)",
    borderRadius: " 12px",
  },
  ScienceTestStyle: {
    padding: 15,
    paddingLeft: 20,
    fontWeight: 600,
    fontSize: "32px",
    color: "#354354",
  },
  ChemistryTestFullPart: {
    height: "60%",
    flexDirection: "column",
    paddingLeft: "5%",
    background:
      "linear-gradient(180deg, #DDE9FF -52.7%, rgba(221, 233, 255, 0) 50%)",
  },

  ResultItems: {
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    borderRadius: "12px",
  },
  Grid1: {
    gridColumnGap: "7px",
    display: "flex",
  },
  GridContainer2: {
    display: "flex",
    gridRowGap: "145px",
  },
  ButtonStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  GridContainer1: {
    display: "flex",
  },
  GridContainer: {
    display: "flex",
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    borderRadius: "12px",
    background: "#FFFFFF",
  },
  GridItemStyle: {
    borderRadius: "12px",
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    background: "#FFFFFF",
  },
  TypoStyle: {
    color: "#354354",
    fontWeight: "600",
  },
  TypoStyle1: {
    color: "#354354",
    fontWeight: "400",
  },

  TypoStyle2: {
    color: "#354354",
    fontWeight: "500",
  },
  TypoStyle3: {
    color: "#354354",
    fontWeight: "300",
  },
  TypoStyle4: {
    fontWeight: "500",

    color: "#5B656F",
  },
  GridItemStyle1: {
    alignContent: "center",
    justifyContent: "center",
  },
  GridItemStyle2: {
    gridColumnGap: "7px",
    paddingLeft: "10px",
    paddingRight: "15px",
    display: "flex",
    borderRadius: "12px",
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    background: "#FFFFFF",
  },
  GridItemStyle3: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  collapseWrap: ({ mediaQuery }) => ({
    "& .MuiContainer-root": {
      width: !mediaQuery && "calc(100% - 32px) !important",
      paddingLeft: !mediaQuery && 0,
      paddingRight: !mediaQuery && 0,
    },
  }),
}));
