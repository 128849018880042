import { FormGroup } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

export default function MultiChoice({ choice, handleChange, selectedChoice }) {
  const CheckBoxWithHtml = (data) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            padding: "0.5rem 0",
          }}
        >
          <Checkbox
            checked={selectedChoice.includes(data.data.id)}
            value={data.data.id}
            onChange={handleChange}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: `${data?.data?.text}`,
            }}
            variant="body1"
          />
        </div>
      </>
    );
  };
  return (
    <FormGroup>
      {choice?.map((el) => (
        <FormControlLabel
          value={el.id}
          style={{ borderBottom: "1px solid #686868" }}
          control={<CheckBoxWithHtml data={el} />}
        />
      ))}
    </FormGroup>
  );
}
