import React from "react";
import "./CustomFooter.css";
import { styled } from "@mui/material/styles";
import Typo from "../typo/Typo";
import LinearProgress from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  // [theme.breakpoints.up('lg')]: {
  height: 4,
  width: 380,
  // width:500,
  borderRadius: 39,
  alignItems: "center",
  padding: 10,
  marginTop: 2,
  display: "flex",

  // },
}));

const Prog = styled(LinearProgress)(({ theme }) => ({
  // [theme.breakpoints.down('md')]: {
  height: 4,
  width: 350,
  borderRadius: 39,
  alignItems: "center",
  padding: 10,
  marginTop: 2,
  display: "flex",
  marginRight: 40,
  // background: "linear-gradient(91deg, #22D1EE 4.75%, #03E2E2 99.26%)",
  // },
}));
export const Progress = (props) => {
  const percentage =
    (props?.data?.currentQuestion / props?.data?.totalQuestion) * 100;
  return (
    <div
      style={{ display: "flex", flexDirection: "row" }}
      className="progressBarAlign"
    >
      <Prog
        variant="determinate"
        value={percentage}
        style={{
          width: "100%",
        }}
      >
        <BorderLinearProgress
          variant="determinate"
          value={percentage}
          id="bar"
          // style={{ backgroundColor: "red" }}
        />
      </Prog>
      <Typo
        variant="body1"
        style={{ color: "#fff", marginLeft: 3, width: "100%" }}
        // className={classes.questHead}
      >
        {props?.data?.currentQuestion} / {props?.data?.totalQuestion}{" "}
        <b>Questions</b>
      </Typo>
    </div>
  );
};
