import { Grid } from "@mui/material";
import InputField from "../common-components/InputField";
import { CustomTypo, ErrorMsg, InputBox, useStyles } from "./Styles";
import DatePicker from "../common-components/DatePicker";
import { isNumberWithDot } from "../../utils/Validation";
import DropDown from "../common-components/DropDown";

function SecondaryEducation({
  pass,
  handleInputChange,
  classXBoard,
  classXIIBoard,
  sscPercentage,
  tenthPassingYearPicker,
  tenthPassingYear,
  twelthPassingYearPicker,
  twelthPassingYear,
  handleChange,
  hscPercentage,
  getFormDetails,
  errorMsg,
  allState,
}) {
  const classes = useStyles();
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <CustomTypo variant={"body2"} className={classes.sectionText}>
          {"Section-4"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <CustomTypo variant={"h6"} color={"#354354"} fontWeight={600}>
          {"Secondary Education"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2.75} pb={2.75}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class XII Board "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={pass || []}
                    value={classXIIBoard}
                    disabled={getFormDetails?.hscExamBoard?.name ? true : false}
                    getOptionLabel={(item) => item.name}
                    placeholder={"Select XII board"}
                    hasNoBorder
                    name={"classXIIBoard"}
                    onChange={(e, newValue) =>
                      handleInputChange("classXIIBoard", newValue)
                    }
                  />
                </InputBox>
                {classXIIBoard ? null : (
                  <ErrorMsg>{errorMsg.classXIIBoard}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class XII percentage "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={"number"}
                    autoComplete="off"
                    placeholder={"Enter XII percentage"}
                    onChange={(evt) => {
                      if (
                        evt.target.value > 100 ||
                        evt.target.value === "0" ||
                        exceptThisSymbols.includes(evt.key)
                      ) {
                        evt.preventDefault();
                      } else {
                        handleChange(evt);
                      }
                    }}
                    onKeyPress={(evt) => {
                      if (isNumberWithDot(evt)) {
                        evt.preventDefault();
                      }
                    }}
                    inputProps={{ min: 1, max: 100 }}
                    value={hscPercentage}
                    onKeyDown={(e) => {
                      // exceptThisSymbols.includes(e.key) && e.preventDefault()
                      if (e.keyCode === 38 || e.keyCode === 40) {
                        console.log("working...");
                        e.preventDefault();
                      }
                    }}
                    name={"hscPercentage"}
                    hasNoBorder
                    disabled={getFormDetails?.hscPercentage ? true : false}
                  />
                </InputBox>
                {hscPercentage ? null : (
                  <ErrorMsg>{errorMsg.hscPercentage}</ErrorMsg>
                )}
                {allState?.hscPercentage ? (
                  <ErrorMsg>{errorMsg.hscPercentageCGPAerr}</ErrorMsg>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class XII passing year "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DatePicker
                    value={twelthPassingYear}
                    views={["year", "month"]}
                    placeholder={"Enter XII passing year"}
                    onChange={(e) => twelthPassingYearPicker(e)}
                    hasNoBorder
                    disableFuture
                    disabled={getFormDetails.hscEndDate ? true : false}
                  />
                </InputBox>
                {twelthPassingYear ? null : (
                  <ErrorMsg>{errorMsg.twelthPassingYear}</ErrorMsg>
                )}
                {twelthPassingYear &&
                new Date(allState?.tenthPassingYear)?.getFullYear() >
                  new Date(allState?.twelthPassingYear)?.getFullYear() ? (
                  <ErrorMsg>{errorMsg.validTwelthPassingYear}</ErrorMsg>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class X Board "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={pass || []}
                    value={classXBoard}
                    getOptionLabel={(item) => item.name}
                    disabled={getFormDetails?.sscExamBoard?.name ? true : false}
                    placeholder={"Select X board"}
                    hasNoBorder
                    onChange={(e, newValue) =>
                      handleInputChange("classXBoard", newValue)
                    }
                  />
                </InputBox>
                {classXBoard ? null : (
                  <ErrorMsg>{errorMsg.classXBoard}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class X percentage "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={"number"}
                    autoComplete="off"
                    placeholder={"Enter X percentage"}
                    value={sscPercentage}
                    onChange={(evt) => {
                      if (
                        evt.target.value > 100 ||
                        evt.target.value === "0" ||
                        exceptThisSymbols.includes(evt.key)
                      ) {
                        evt.preventDefault();
                      } else {
                        handleChange(evt);
                      }
                    }}
                    onKeyDown={(evt) => {
                      // if (isNumberWithDot(evt)) evt.preventDefault();
                      if (evt.keyCode === 38 || evt.keyCode === 40) {
                        console.log("working....");
                        evt.preventDefault();
                      }
                    }}
                    disabled={getFormDetails?.sscPercentage ? true : false}
                    name={"sscPercentage"}
                    // onKeyDown={(e) =>
                    //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                    // }
                    inputProps={{ min: 1, max: 100 }}
                    hasNoBorder
                  />
                </InputBox>
                {sscPercentage ? null : (
                  <ErrorMsg>{errorMsg.sscPercentage}</ErrorMsg>
                )}
                {allState?.sscPercentage ? (
                  <ErrorMsg>{errorMsg.sscPercentageCGPAerr}</ErrorMsg>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Class X passing year "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox className={classes.yearOfTextBox}>
                  <DatePicker
                    value={tenthPassingYear}
                    views={["year", "month"]}
                    placeholder={"Enter X passing year"}
                    onChange={(e) => tenthPassingYearPicker(e)}
                    hasNoBorder
                    disableFuture
                    disabled={getFormDetails.sscEndDate ? true : false}
                  />
                </InputBox>
                {tenthPassingYear ? null : (
                  <ErrorMsg>{errorMsg.tenthPassingYear}</ErrorMsg>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SecondaryEducation;
