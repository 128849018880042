export const isNumber = (evt) => {
  return (evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57;
};

export const isPoneNumber = (evt) => {
  return (
    (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
    evt.which > 57 ||
    evt?.target?.value?.length >= 10
  );
};

export const isEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// Alphabets Only
export const isAlphabetOnly = (evt) => {
  let value = evt.target.value;
  var alphabetsOnly = value.replace(/[^A-Za-z\s]/g, '');
  if (value === alphabetsOnly || evt.keyCode === 32) {
    return true;
  } else {
    return false;
  }
};

// Alpha Numerics Only
export const isAlphaNumericOnly = (evt) => {
  let value = evt.target.value;
  var alphaNumericsOnly = value.replace(/[^a-zA-Z0-9]/g, '');
  if (value === alphaNumericsOnly && value.length <= 12) {
    return true;
  } else {
    return false;
  }
};

export const isNumberWithDot = (evt) => {
  return (
    (evt.which !== 8 && evt.which !== 0 && evt.which < 48 && evt.key !== '.') || evt.which > 57
  );
};
