import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  typoGrid: {
    display: "flex",
    flexDirection: "column !important",
    gap: "4px",
  },
  typo: {
    fontFamily: "Poppins !important",
  },
  typoTitle: {
    color: "#000000",
  },
  typoSubTitle: {
    color: "#828D98",
  },
  questCard: {
    padding: 25,
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.2) !important",
    borderRadius: "12px !important",
    color: "#2D6CDF !important",
    marginBottom: "25px",
  },
  questHead: {
    paddingBottom: "20px",
    color: "#052A4E",
    fontWeight: "600 !important",
  },
  "@media (max-width:600px)": {
    buttonUpload: {
      marginBottom: "100px !important",
    },
  },
}));
