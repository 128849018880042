import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Fragment } from 'react';
import InputField from './InputField';

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function CreatableDropdown({
  id,
  value,
  name,
  options,
  optionLabel,
  filterOptions,
  textProps,
  onChange,
}) {
  return (
    <Autocomplete
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option[optionLabel];
      }}
      renderInput={(params) => (
        <InputField {...params} autoComplete={true} hasNoBorder {...textProps} />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      //   disableCloseOnSelect
      debug
      //   multiple
      //   freeSolo
    />
  );
}

export default CreatableDropdown;
