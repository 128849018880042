import React, { useEffect, useState } from "react";
import Typo from "../typo/Typo";

export default function TimerCompo({
  time,
  getRemainingTime,
  mediaQuery,
  refresh,
  stopVideoRecording,
}) {
  const [finalTime, setFinalTime] = useState(time);
  console.log(finalTime);
  const pad = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  useEffect(() => {
    setFinalTime(time);
  }, [refresh]);
  useEffect(() => {
    getTime();
    if (stopVideoRecording) {
      setFinalTime(finalTime);
    } else if (finalTime !== 0) {
      setTimeout(() => {
        setFinalTime(finalTime - 1);
      }, 1000);
    } else {
    }
  }, [finalTime]);

  const getTime = () => {
    getRemainingTime(finalTime);
  };

  let timestamp = Number(finalTime);
  var h = Math.floor(timestamp / 3600);
  var m = Math.floor((timestamp % 3600) / 60);
  var s = Math.floor((timestamp % 3600) % 60);

  var hours = h > 0 ? h : "";
  var minutes = m > 0 ? m : "";
  var seconds = s > 0 ? s : "";
  if (seconds === "") {
    console.log(seconds, "seconds");
    seconds = "0";
  }
  console.log(seconds);
  return (
    <div>
      <Typo variant={mediaQuery ? "h6" : "h7"} id="reaoning">
        {hours
          ? `${pad(hours && hours)} : ${pad(minutes && minutes)}:${pad(
              seconds && seconds
            )}`
          : minutes
          ? `00 : ${pad(minutes && minutes)} : ${pad(seconds && seconds)}`
          : `00 : 00:  ${pad(seconds && seconds)}`}
      </Typo>
    </div>
  );
}