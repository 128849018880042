import React from 'react'

export const MeanEllipse = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8.5" cy="8.5" r="7.5" stroke="#5842F6" stroke-width="2"/>
</svg>

  )
}
