import { Radio, FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import React from 'react';
import { useStyles } from '../question-ans/Style';
export const AnswerRadioType = ({ value, handleChange, choice, selectedChoice }) => {
  const classes = useStyles();
  const CheckBoxWithHtml = (data) => {
    // console.log(data?.data?.text, 'choice');
    return (
      <>
        <Radio checked={selectedChoice[0] === data?.value ? true : false} value={data?.data?.id} />
        <div
          dangerouslySetInnerHTML={{
            __html:
              data?.data?.type === 'IMAGE'
                ? `<img src=${data?.data?.choiceImage} />`
                : `${data?.data?.text}`,
          }}
          variant='body1'
          className={classes.contentWrapStyle}
        />
      </>
    );
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        // defaultValue={selectedChoice}
        onChange={handleChange}
      >
        {choice?.map((el) => (
          <FormControlLabel
            value={el.id}
            id={el.id}
            control={<CheckBoxWithHtml data={el} />}
            sx={{
              paddingTop: 1.5,
              paddingBottom: 1,
              borderBottom: '1px solid #686868',
            }}
            style={{ borderBottom: '1px solid #686868' }}
            // label={el.text}
            // dangerouslySetInnerHTML={createMarkup}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
