import { createTheme, Dialog, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomAppbar from "./components/appbar/CustomAppbar";
import { PopupCard } from "./components/popup/PopupCard";
import { Rendering } from "./components/question-ans/Rendering";
import ResultDetails from "./components/result-details/ResultDetails";
import { ConfirmPage } from "./components/test-confirmation/ConfirmPage";
import  { useEffect } from "react";
import { Navigate } from "react-router";
import { useDispatch} from "./redux/Store"

// import { InstructionPart } from "./components/test-instruction/Instruction";
import { ChemistryTest } from "./components/test-section/TestSection";
import { InstructionPart } from "./components/Instruction/InstructionPart";
import Video from "./components/VideoAudioRecording/Video";
import LinearDeterminate from "./components/ProgressBar/Progress";
import  {getFormStatus} from "./redux/slices/Test";
import { TestProct } from "./components/tpr-screen/TestProct";
import Form from "./components/form/Index";
import { PATH } from "./routes/routePath";
import { studentId } from "./utils/Constants";
import WebcamDemo from "./components/Proctoring/WebcamDemo";
import { AnswerFile } from "./components/file-upload/FileUpload";

function App() {
 
  






 
  const [open, setOpen] = React.useState(false);
  const theme = createTheme();

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAppbar />
      <BrowserRouter>
        <Routes>
        <Route path='/'element={<Form />} />
          <Route path='/Instruction' element={<InstructionPart />} />
          <Route path='/TestInstruction' element={<ChemistryTest />} />
          <Route path='/QuestionAns' element={<Rendering />} />
          <Route
            path='/QuestionAns/:testExeId/:quesId'
            element={<Rendering />}
          />
          <Route
            path='/Confirmation/:testName/:testExecutionId'
            element={<ConfirmPage />}
          />
          <Route path="/Popup" element={<PopupCard />} />
          <Route path="/Result/:testExecutionId" element={<ResultDetails />} />
          <Route path="/VideoRec" element={<Video/>}/>
          <Route path="/Fileupload" element={<AnswerFile/>}/>
          <Route path="/Progress" element={<LinearDeterminate/>}/>
          <Route path ="/Proctor" element = {<WebcamDemo/>}/>
          <Route path = "/TestProctoring" element = {<TestProct/>}/>

          {/* <Route path='/Popup' element={<PopupCard />} />
          <Route path='/Result/:testExecutionId' element={<ResultDetails />} /> */}
          {/* <Route path={PATH.form} element={<Form />} /> */}
          {/* <ThemeProvider theme={lightTheme}> */}
          {/* <CustomAppbar/> */}
          {/* <CustomFooter/> */}
          {/* <InstructionPart/> */}
          {/* <ChemistryTest/> */}
          {/* <QuestionComponent/> */}
          {/* <AnswerRadioType/> */}
          {/* <AnswerCheckBox/> */}
          {/* <AnsSingleChoiceImage/> */}
          {/* <AnswerFile/> */}
          {/* <ConfirmPage/>  */}
          {/* <PopupCard/> */}
          {/* <CustomDropzone/> */}
          {/* <TestHeader/> */}
          {/* <ResultPart/> */}

          {/* <Rendering/> */}
          {/* </ThemeProvider> */}
        </Routes>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <PopupCard />
        </Dialog>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
