import { Grid } from '@mui/material';
import InputField from '../common-components/InputField';
import { CustomTypo, ErrorMsg, InputBox, useStyles } from './Styles';
import { isAlphaNumericOnly, isAlphabetOnly, isPoneNumber } from '../../utils/Validation';
import DropDown from '../common-components/DropDown';

const LOCATION = [
  {
    value: 'Bangalore',
    label: 'Bangalore',
  },
  {
    value: 'Hyderabad',
    label: 'Hyderabad',
  },
  {
    value: 'Mumbai',
    label: 'Mumbai',
  },
  {
    value: 'Pune',
    label: 'Pune',
  },
  {
    value: 'Delhi',
    label: 'Delhi',
  },
  {
    value: 'Kolkota',
    label: 'Kolkota',
  },
  {
    value: 'Trichy',
    label: 'Trichy',
  },
  {
    value: 'Kochi',
    label: 'Kochi',
  },
];

function OtherDetails({
  fatherName,
  motherName,
  aadharOrPanNo,
  parentsPhoneNumber,
  locationDropDown,
  preferredLocation,
  handleChange,
  getFormDetails,
  errorMsg,
  allState,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <CustomTypo variant={'body2'} className={classes.sectionText}>
          {'Section-5'}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <CustomTypo variant={'h6'} color={'#354354'} fontWeight={600}>
          {'Other Details'}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2.75} pb={2.75}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {"Father's Name "}
                  <CustomTypo
                    fontSize={'14px'}
                    color={'#FF0000'}
                    variant={'caption'}
                    fontWeight={500}
                  >
                    {'*'}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    autoComplete='off'
                    value={fatherName}
                    onChange={(evt) => {
                      if (isAlphabetOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    placeholder={"Enter Father's Name"}
                    name='fatherName'
                    disabled={getFormDetails?.fatherName ? true : false}
                    hasNoBorder
                  />
                </InputBox>
                {fatherName ? null : <ErrorMsg>{errorMsg.fatherName}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'Aadhar/PAN number (any one) '}
                  <CustomTypo
                    fontSize={'14px'}
                    color={'#FF0000'}
                    variant={'caption'}
                    fontWeight={500}
                  >
                    {'*'}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    name={'aadharOrPanNo'}
                    autoComplete='off'
                    placeholder={'Enter Aadhar/PAN number'}
                    value={aadharOrPanNo}
                    disabled={getFormDetails?.aadharOrPanNo ? true : false}
                    onChange={(evt) => {
                      console.log(isAlphaNumericOnly(evt), 'isAlphaNumericOnly(evt)');
                      if (isAlphaNumericOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    inputProps={{ min: 0 }}
                    hasNoBorder
                  />
                </InputBox>
                {aadharOrPanNo ? null : <ErrorMsg>{errorMsg.aadharOrPanNo}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'Preferred Location '}
                  <CustomTypo
                    fontSize={'14px'}
                    color={'#FF0000'}
                    variant={'caption'}
                    fontWeight={500}
                  >
                    {'*'}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={LOCATION || []}
                    getOptionLabel={(item) => item.value}
                    placeholder={'Select Preferred Location'}
                    hasNoBorder
                    value={preferredLocation}
                    name={'preferredLocation'}
                    disabled={getFormDetails?.preferredLocation ? true : false}
                    onChange={(e, obj) => locationDropDown(e, obj)}
                  />
                </InputBox>
                {preferredLocation?.value ? null : (
                  <ErrorMsg>{errorMsg.preferredLocation}</ErrorMsg>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {"Mother's Name "}
                  <CustomTypo
                    fontSize={'14px'}
                    color={'#FF0000'}
                    variant={'caption'}
                    fontWeight={500}
                  >
                    {'*'}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    autoComplete='off'
                    value={motherName}
                    onChange={(evt) => {
                      if (isAlphabetOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    placeholder={"Enter Mother's Name"}
                    name='motherName'
                    disabled={getFormDetails?.motherName ? true : false}
                    hasNoBorder
                  />
                </InputBox>
                {motherName ? null : <ErrorMsg>{errorMsg.motherName}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'Parent’s Phone Number '}
                  <CustomTypo
                    fontSize={'14px'}
                    color={'#FF0000'}
                    variant={'caption'}
                    fontWeight={500}
                  >
                    {'*'}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={'number'}
                    autoComplete='off'
                    name={'parentsPhoneNumber'}
                    placeholder={'Enter Parent’s Phone Number'}
                    onKeyPress={(evt) => {
                      if (isPoneNumber(evt)) evt.preventDefault();
                    }}
                    value={parentsPhoneNumber}
                    disabled={getFormDetails?.parentsPhoneNumber ? true : false}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ min: 0 }}
                    hasNoBorder
                  />
                </InputBox>
                {parentsPhoneNumber ? null : <ErrorMsg>{errorMsg.parentsPhoneNumber}</ErrorMsg>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OtherDetails;
