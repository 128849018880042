import { useMediaQuery, useTheme } from "@material-ui/core";
import { Box, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ContentWrapper, Wrapper } from "../../assets/styles/CommonStyles";
import {
  getCollege,
  getDegree,
  getDepartment,
  putStudentEductionInformation,
  getFormSection,
} from "../../redux/slices/Test";
import { useDispatch } from "../../redux/Store";
import { CustomFooter } from "../footer/CustomFooter";
import CustomSnackbar from "../snack/snack";
import { useStyles } from "./Styles";
import Typo from "../typo/Typo";
import PersonalInfo from "./PersonalInfo";
import UgInfo from "./UgInfo";
import SecondaryEducation from "./SecondaryEducation";
import { getFormStatus } from "../../redux/slices/Test";
import React from "react";
import { examBoard } from "../../redux/slices/Test";
import { studentId } from "../../utils/Constants";
import { accessToken, removeCookie } from "../../utils/Constants";
import { COOKIE_DOMAIN } from "../../api/Url";
import { ReactComponent as BackIcon } from "../../assets/icons/arrow_back.svg";
import { Loaders } from "../common-components/Loader";
import PgInfo from "./PgInfo";
import OtherDetails from "./OtherDetails";

function Index(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  // const [section, setSection] = useState(1);
  const [snack, setSnack] = useState({
    open: false,
    message: null,
    color: "",
  });
  const { open, message, color } = snack;
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const testExecutionId = params?.testExecutionId || null;

  const [option, setOption] = useState({
    degree: [],
  });
  const [dep, setDep] = useState({
    department: [],
  });
  const [colleges, setColleges] = useState({
    college: [],
  });
  const [board, setBoard] = useState({
    examBoard: [],
  });
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    clsForm: [],
  });

  const [state, setState] = useState({
    degree: null,
    branch: null,
    phoneNumber: null,
    aadharOrPanNo: null,
    parentsPhoneNumber: null,
    collegeName: null,
    activeBacklogs: null,
    pgSemester: null,
    backlogNumber: null,
    yearOfPassing: null,
    pgEndDate: null,
    studentName: null,
    fatherName: null,
    pgDegree: null,
    pgCollege: null,
    pgDepartment: null,
    motherName: null,
    gender: null,
    preferredLocation: null,
    educationGap: null,
    classXIIBoard: null,
    classXBoard: null,
    sscPercentage: null,
    tenthPassingYear: null,
    tenthYearFormat: null,
    twelthPassingYear: null,
    twelthYearFormat: null,
    hscPercentage: null,
    pgPercentage: null,
    ugPercentage: null,
    dateOfBirth: null,
    email: null,
    FresherOrExperienced: null,
  });

  useEffect(() => {
    dispatch(
      getFormStatus(studentId, (res) => {
        if (res.success) {
          navigate("/");
        } else {
          navigate("/Instruction");
        }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      examBoard(studentId, (res) => {
        if (res.success) {
          setBoard({
            ...board,
            examBoard: res.data,
          });
        } else {
          setBoard({
            ...board,
            examBoard: [],
          });
        }
      })
    );

    dispatch(
      getFormSection(studentId, (res) => {
        if (res) {
          setLoading(false);
        }
        if (res.success) {
          setForm({
            ...form,
            clsForm: res.data,
          });
          setState({
            ...state,
            collegeName: res.data.ugCollege,
            studentName: res.data.name,
            fatherName: res.data.fatherName,
            pgDegree: res.data.pgDegree,
            pgCollege: res.data.pgCollege,
            pgDepartment: res.data.pgDepartment,
            motherName: res.data.motherName,
            dateOfBirth: res.data.dob,
            yearOfPassing: res.data.ugEndDate,
            tenthPassingYear: res.data.sscEndDate,
            twelthPassingYear: res.data.hscEndDate,
            email: res.data.emailId,
            phoneNumber: res.data.phoneNumber,
            aadharOrPanNo: res.data.aadharOrPanNo,
            parentsPhoneNumber: res.data.parentsPhoneNumber,
            gender:
              res.data.gender === null
                ? { value: "" }
                : { value: res.data.gender },
            preferredLocation:
              res.data.preferredLocation === null
                ? { value: "" }
                : { value: res.data.preferredLocation },
            degree: res.data.ugDegree,
            branch: res.data.ugDepartment,
            activeBacklogs: res.data.activeBacklogs,
            pgSemester: res.data.pgSemester,
            backlogNumber: res.data.clearedBacklogs,
            ugPercentage: res.data.ugPercentage,
            classXBoard: res.data.sscExamBoard,
            classXIIBoard: res.data.hscExamBoard,
            sscPercentage: res.data.sscPercentage,
            hscPercentage: res.data.hscPercentage,
            pgPercentage: res.data.pgPercentage,
            FresherOrExperienced:
              res.data.experienceStatus === null
                ? { value: "" }
                : { value: res.data.experienceStatus },
            educationGap:
              res.data.educationGapStr === null
                ? { value: "" }
                : { value: res.data.educationGapStr },
          });
        } else {
          setForm({
            ...form,
            clsForm: [],
          });
        }
      })
    );

    dispatch(
      getDegree(studentId, (res) => {
        if (res.success) {
          setOption({
            ...option,
            degree: res.data,
          });
        } else {
          setOption({
            ...option,
            degree: [],
          });
        }
      })
    );
    dispatch(
      getDepartment(studentId, (res) => {
        if (res.success) {
          setDep({
            ...dep,
            department: res.data,
          });
        } else {
          setOption({
            ...dep,
            department: [],
          });
        }
      })
    );
    dispatch(
      getCollege(studentId, (res) => {
        if (res.success) {
          setColleges({
            ...colleges,
            college: res.data,
          });
        } else {
          setOption({
            ...colleges,
            college: [],
          });
        }
      })
    );
  }, []);

  const {
    degree,
    branch,
    collegeName,
    activeBacklogs,
    pgSemester,
    backlogNumber,
    yearOfPassing,
    pgEndDate,
    studentName,
    fatherName,
    pgDegree,
    pgCollege,
    pgDepartment,
    motherName,
    gender,
    preferredLocation,
    educationGap,
    classXIIBoard,
    classXBoard,
    phoneNumber,
    aadharOrPanNo,
    parentsPhoneNumber,
    sscPercentage,
    tenthPassingYear,
    twelthPassingYear,
    hscPercentage,
    pgPercentage,
    ugPercentage,
    dateOfBirth,
    email,
    FresherOrExperienced,
  } = state;

  const Degree = option?.degree?.map((item) => item);
  const Department = dep?.department?.map((item) => item);
  const Exam = board?.examBoard?.map((item) => item);
  const College = colleges?.college?.map((item) => item);

  const pad = (d) => {
    return d < 10 ? "0" + d?.toString() : d?.toString();
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: null,
    });
  };

  // const handleBack = () => {
  //   if (section > 1) {
  //     setSection(section - 1);
  //   } else {
  //   }
  // };
  // const rose = () => {
  //   setErrors(validate(ValueDate));
  // };

  const handleChangeDropdown = (e, newValue) => {
    new Promise((resolve, reject) => {
      let obj = {};
      if (typeof newValue === "string") {
        console.log("string");
        obj = {
          id: null,
          name: newValue?.trim(),
        };
      } else if (newValue && newValue.inputValue) {
        console.log("else if");
        // Create a new value from the user input
        obj = {
          id: null,
          name: newValue.inputValue?.trim(),
        };
      } else {
        obj = newValue;
      }
      resolve(obj);
    }).then((value) => {
      setState({
        ...state,
        collegeName: value,
      });
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pgPercentage") {
      if (value === "0") {
        e.preventDefault();
      }
    }
    if (name === "ugPercentage") {
      if (value === "0") {
        e.preventDefault();
      }
    }
    if (name === "hscPercentage") {
      if (value === "0") {
        e.preventDefault();
      }
    }
    if (name === "sscPercentage") {
      if (value === "0") {
        e.preventDefault();
      }
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const dropDownValue = (e, valueObj) => {
    setState({
      ...state,
      gender: valueObj,
    });
  };

  const locationDropDown = (e, valueObj) => {
    setState({
      ...state,
      preferredLocation: valueObj,
    });
  };

  const EducationDropDown = (e, objValue) => {
    setState({
      ...state,
      educationGap: objValue,
    });
  };

  const FresherOrExperiencedDropDown = (e, objValue) => {
    setState({
      ...state,
      FresherOrExperienced: objValue,
    });
  };

  const handleInputChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handlePickerChange = (e) => {
    setState({
      ...state,
      yearOfPassing: e,
    });
  };

  const pgPassingYearPicker = (e) => {
    setState({
      ...state,
      pgEndDate: e,
    });
  };

  const tenthPassingYearPicker = (e) => {
    setState({
      ...state,
      tenthPassingYear: e,
    });
  };

  const twelthPassingYearPicker = (e) => {
    setState({
      ...state,
      twelthPassingYear: e,
    });
  };

  const handleChangeDateOfBirth = (e) => {
    setState({
      ...state,
      dateOfBirth: e,
    });
  };

  const ValueDate = {
    name: studentName,
    phoneNumber: phoneNumber,
    aadharOrPanNo: aadharOrPanNo,
    parentsPhoneNumber: parentsPhoneNumber,
    dob: dateOfBirth,
    gender: gender?.value,
    preferredLocation: preferredLocation?.value,
    activeBacklogs: activeBacklogs ? activeBacklogs : null,
    pgSemester: pgSemester ? Number(pgSemester) : null,
    clearedBacklogs: backlogNumber ? backlogNumber : null,
    sscPercentage: Number(sscPercentage),
    ugEndDate:
      pad(new Date(state?.yearOfPassing)?.getMonth() + 1) +
      "/" +
      pad(new Date(state?.yearOfPassing)?.getFullYear()),
    pgEndDate: pgEndDate
      ? pad(new Date(state?.pgEndDate)?.getMonth() + 1) +
      "/" +
      pad(new Date(state?.pgEndDate)?.getFullYear())
      : null,
    sscEndDate:
      pad(new Date(state?.tenthPassingYear)?.getMonth() + 1) +
      "/" +
      pad(new Date(state?.tenthPassingYear)?.getFullYear()),
    hscEndDate:
      pad(new Date(state?.twelthPassingYear)?.getMonth() + 1) +
      "/" +
      pad(new Date(state?.twelthPassingYear)?.getFullYear()),
    hscPercentage: Number(hscPercentage),
    pgPercentage: pgPercentage ? Number(pgPercentage) : null,
    ugPercentage: Number(ugPercentage),
    ugCollege: {
      name: collegeName?.name,
    },
    ugDegree: {
      id: degree?.id,
    },
    ugDepartment: {
      id: branch?.id,
    },
    hscExamBoard: {
      id: classXIIBoard?.id,
    },
    sscExamBoard: {
      id: classXBoard?.id,
    },
    emailId: email,
    experienceStatus: FresherOrExperienced?.value,
    educationGap:
      educationGap?.value === "" ||
        educationGap?.value === undefined ||
        educationGap?.value === null
        ? ""
        : educationGap?.value === "No"
          ? false
          : true,
    fatherName: fatherName,
    motherName: motherName,
    pgDegree: pgDegree ? pgDegree : null,
    pgDepartment: pgDepartment ? pgDepartment : null,
    pgCollege: pgCollege ? pgCollege : null,
  };

  const validate = (values) => {
    let errors = {};
    let today = new Date();
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }
    if (!values.aadharOrPanNo) {
      errors.aadharOrPanNo = "Aadhar/PAN number is required";
    }
    if (!values.parentsPhoneNumber) {
      errors.parentsPhoneNumber = "Parent’s Phone Number is required";
    }
    if (!values.dob) {
      errors.dob = "Date of Birth is required";
    } else if (today?.toDateString() === new Date(values.dob)?.toDateString()) {
      errors.dob = "Enter a valid date of birth";
    } else if (
      new Date(state?.tenthPassingYear)?.getFullYear() -
      new Date(state?.dateOfBirth)?.getFullYear() <
      12
    ) {
      errors.dob = "Enter a valid date of birth";
    }
    if (!values.gender) {
      errors.gender = "Gender is required";
    }
    if (!values.preferredLocation) {
      errors.preferredLocation = "Preferred Location is required";
    }
    if (!values.ugPercentage) {
      errors.ugPercentage = "UG % is required";
    }
    // if (values?.ugPercentage === 0) {
    //   console.log(values.ugPercentage);
    //   errors.degreeCGPAerr = "Enter a valid CGPA";
    //   console.log(errors.degreeCGPAerr);
    // }
    if (!values.fatherName) {
      errors.fatherName = "Father's Name is required";
    }
    if (!values.motherName) {
      errors.motherName = "Mother's Name is required";
    }
    if (!values?.clearedBacklogs) {
      errors.clearedBacklogs = "History of backlogs is required";
    }
    if (!values?.activeBacklogs) {
      errors.activeBacklogs = "Number of active backlogs is required";
    }
    if (!values.hscPercentage) {
      errors.hscPercentage = "Class XII percentage is required";
    }
    // if (values?.hscPercentage === 0) {
    //   console.log(values.hscPercentage);
    //   errors.hscPercentageCGPAerr = "Enter a valid XII Percentage";
    //   console.log(errors.hscPercentageCGPAerr);
    // }
    if (!values.sscPercentage) {
      errors.sscPercentage = "Class X percentage is required";
    }
    // if (values?.sscPercentage === 0) {
    //   console.log(values.sscPercentage);
    //   errors.sscPercentageCGPAerr = "Enter a valid X Percentage";
    //   console.log(errors.sscPercentageCGPAerr);
    // }
    if (!twelthPassingYear) {
      errors.twelthPassingYear = "Class XII passing year is required";
    }
    if (!tenthPassingYear) {
      errors.tenthPassingYear = "Class X passing year is required";
    }
    if (!state.email) {
      errors.email = "Email Address is required";
    }
    if (!state.FresherOrExperienced?.value) {
      errors.FresherOrExperienced = "Fresher or Experienced is required";
    }
    if (!state.educationGap?.value) {
      errors.educationGap = "Any gap in education is required";
    }
    if (!state.collegeName) {
      errors.collegeName = "UG College Name is required";
    }
    if (!state.branch) {
      errors.branch = "UG Branch is required";
    }
    if (!state.degree) {
      errors.degree = "UG Degree is required";
    }
    if (!state.yearOfPassing) {
      errors.yearOfPassing = "UG Pass-out year is required";
    }
    if (
      new Date(state?.twelthPassingYear)?.getFullYear() >
      new Date(state?.yearOfPassing)?.getFullYear()
    ) {
      errors.validYearOfPassing = "Enter a valid Pass-out year";
    }
    if (!state.classXBoard) {
      errors.classXBoard = "Class X Board is required";
    }
    if (!state.classXIIBoard) {
      errors.classXIIBoard = "Class XII Board is required";
    }
    if (
      new Date(state?.tenthPassingYear)?.getFullYear() >
      new Date(state?.twelthPassingYear)?.getFullYear()
    ) {
      errors.validTwelthPassingYear = "Enter a valid passing year";
    }

    return errors;
  };

  const nextButton = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(ValueDate));

    console.log("testing AE in QA");
  };

  useEffect(() => {
    console.log("first");
    console.log(ValueDate, "ValueDate");
    console.log("reflectingggggggggg");
    const {
      name,
      phoneNumber,
      aadharOrPanNo,
      parentsPhoneNumber,
      activeBacklogs,
      clearedBacklogs,
      college,
      degree,
      ugPercentage,
      department,
      dob,
      gender,
      preferredLocation,
      hscEndDate,
      hscExamBoard,
      hscPercentage,
      sscEndDate,
      sscExamBoard,
      sscPercentage,
      year,
      emailId,
      experienceStatus,
      educationGap,
      fatherName,
      motherName,
    } = ValueDate;
    if (
      name !== null &&
      phoneNumber !== null &&
      aadharOrPanNo !== null &&
      parentsPhoneNumber !== null &&
      activeBacklogs !== null &&
      clearedBacklogs !== null &&
      college !== null &&
      degree !== null &&
      ugPercentage !== null &&
      department !== null &&
      dob !== null &&
      gender !== null &&
      preferredLocation !== null &&
      hscEndDate !== null &&
      hscExamBoard !== null &&
      hscPercentage !== null &&
      sscEndDate !== null &&
      sscExamBoard !== null &&
      sscPercentage !== null &&
      year !== null &&
      emailId !== null &&
      experienceStatus !== null &&
      educationGap !== null &&
      fatherName !== null &&
      motherName !== null
    ) {
      console.log(Object.keys(errors), "11456789");
      if (Object.keys(errors).length === 0) {
        console.log("incoming");
        dispatch(
          putStudentEductionInformation(studentId, ValueDate, (res) => {
            if (res.message === "Student Data Saved Successfully") {
              navigate("/Instruction");
            }
          })
        );
      }
    }
  }, [errors]);

  const onClose = () => {
    removeCookie("studentId", { path: "", domain: COOKIE_DOMAIN });
    removeCookie("accessToken", { path: "", domain: COOKIE_DOMAIN });
    removeCookie("refreshToken", { path: "", domain: COOKIE_DOMAIN });
    window.close();
    window.top.close();
  };

  const renderForm = () => {
    return (
      <>
        <PersonalInfo
          handleChange={handleChange}
          dropDownValue={dropDownValue}
          EducationDropDown={EducationDropDown}
          gender={gender}
          studentName={studentName}
          educationGap={educationGap}
          phoneNumber={phoneNumber}
          dateOfBirth={dateOfBirth}
          getFormDetails={form?.clsForm}
          handleChangeDateOfBirth={handleChangeDateOfBirth}
          errorMsg={errors}
          email={email}
          FresherOrExperiencedDropDown={FresherOrExperiencedDropDown}
          FresherOrExperienced={FresherOrExperienced}
          allState={state}
        />
        <PgInfo
          pgDegree={pgDegree}
          pgDepartment={pgDepartment}
          pgSemester={pgSemester}
          pgPercentage={pgPercentage}
          pgEndDate={pgEndDate}
          pgCollege={pgCollege}
          pgPassingYearPicker={pgPassingYearPicker}
          getFormDetails={form?.clsForm}
          handleChange={handleChange}
          errorMsg={errors}
          allState={state}
        />
        <UgInfo
          degreeOption={Degree}
          departmentOption={Department}
          college={College}
          degree={degree}
          branch={branch}
          getFormDetails={form?.clsForm}
          collegeName={collegeName}
          backlogNumber={backlogNumber}
          activeBacklogs={activeBacklogs}
          yearOfPassing={yearOfPassing}
          ugPercentage={ugPercentage}
          handlePickerChange={handlePickerChange}
          handleChange={handleChange}
          handleChangeDropdown={handleChangeDropdown}
          handleInputChange={handleInputChange}
          errorMsg={errors}
          allState={state}
        />
        <SecondaryEducation
          classXBoard={classXBoard}
          sscPercentage={sscPercentage}
          classXIIBoard={classXIIBoard}
          handleInputChange={handleInputChange}
          tenthPassingYearPicker={tenthPassingYearPicker}
          tenthPassingYear={tenthPassingYear}
          twelthPassingYear={twelthPassingYear}
          twelthPassingYearPicker={twelthPassingYearPicker}
          pass={Exam}
          handleChange={handleChange}
          hscPercentage={hscPercentage}
          getFormDetails={form?.clsForm}
          errorMsg={errors}
          allState={state}
        />
        <OtherDetails
          fatherName={fatherName}
          motherName={motherName}
          aadharOrPanNo={aadharOrPanNo}
          parentsPhoneNumber={parentsPhoneNumber}
          locationDropDown={locationDropDown}
          preferredLocation={preferredLocation}
          handleChange={handleChange}
          getFormDetails={form?.clsForm}
          errorMsg={errors}
          allState={state}
        />
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loaders></Loaders>
      ) : (
        <Wrapper>
          <ContentWrapper className={classes.rootBase}>
            <Box
              p={mediaQuery ? "40px 100px" : "20px 25px"}
              className={classes.formBar}
            >
              {/* <Box
            className={
              mediaQuery ? classes.backIconPositioned : classes.backIconWrapper
            }
          >
            <IconButton
              disabled={section === 1}
              className={classes.iconButtonStyle}
              onClick={handleBack}
            >
              <BackIcon />
            </IconButton>
          </Box> */}
              <Grid container>
                <Grid item xs={12}>
                  <Typo
                    className={classes.testHeaderName}
                    fontWeight={600}
                    variant={"h4"}
                    color={"#354354"}
                    paddingBottom={1}
                  >
                    {params?.testName || "Test name"}
                  </Typo>
                </Grid>
                <Grid item xs={12}>
                  <Typo variant={"body2"}>
                    {"Please fill in the details for proceeding for the test"}
                  </Typo>
                </Grid>
                <Grid item md={12}>
                  <Box pt={4}>{renderForm()}</Box>
                </Grid>
              </Grid>
            </Box>
          </ContentWrapper>
          <CustomFooter
            endExam={onClose}
            open={openDialog}
            nextProp={() => nextButton()}
            secondaryButtonText={"Quit"}
            primaryButtonText={"Submit"}
            hasNoProgressBar
          />
          <CustomSnackbar
            open={open}
            handleClose={handleSnackClose}
            type={color}
            text={message}
          />
        </Wrapper>
      )}
    </>
  );
}

export default Index;