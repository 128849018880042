import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  "@media (max-width: 600px)": {
    yearOfTextBox: {
      marginBottom: "60px",
    },
    testHeaderName: {
      marginTop: "65px !important",
    },
  },
  root: {
    color: theme.palette.primary.main,
  },
  rootBase: {
    "& *": {
      fontFamily: "Poppins !important",
    },
  },
  formBar: {
    position: "relative",
    background:
      "linear-gradient(180deg, #DDE9FF -52.7%, rgba(221, 233, 255, 0) 100%)",
  },
  iconButtonStyle: {
    padding: "12px",
    background: "#FFFFFF !important",
    boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1) !important",
  },
  spacer: {
    width: "60%",
  },
  sectionText: {
    fontWeight: "600 !important",
    background: "linear-gradient(91deg, #2D6CDF 4.75%, #6757D8 99.26%)",
    "-webkitBackgroundClip": "text",
    "-webkitTextFillColor": "transparent",
  },
  backIconWrapper: {
    paddingBottom: "20px",
  },
  backIconPositioned: {
    position: "absolute",
    top: "36px",
    left: "32px",
  },
}));

export const InputBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(146, 170, 212, 0.12);
  border-radius: 8px;
  padding: 0 2px;
  max-width: 320px;
  margin-top: 6px;
  input {
    font-size: 14px;
    color: #354354;
    font-weight: 600;
    &::placeholder {
      font-weight: 400;
      color: #828d98;
    }
  }
`;

export const ErrorMsg = styled.div`
  margin: 10px 5px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: red;
  font-weight: 500;
`;

export const CustomTypo = styled(Typography)``;