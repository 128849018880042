import React from "react";
import Typo from "../typo/Typo";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "./Style";
import { Container, Card } from "@mui/material";
import Radio from "@mui/material/Radio";

const Bundle = ({
  onChange,
  selectedChoice,
  correctChoice,
  choice,
  totalBundle,
}) => {
  const bundleLength = totalBundle;
  const choices = choice;
  var alphaOption = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  var romanLetter = ["i", "ii", "iii", "iv", "v"];

  const renderHeader = () => {
    let arr = [];
    for (let i = 1; i <= bundleLength; i++) {
      arr.push(`blank (${romanLetter[i - 1]})`);
    }
    return arr;
  };

  const renderOptions = () => {
    let arr = [];
    for (let i = 1; i <= bundleLength; i++) {
      let choice = [];
      for (let j = 0; j < choices.length; j++) {
        if (choices[j].bundleNo === i) {
          choice.push(choices[j]);
        }
      }
      arr.push({
        bundleNo: i,
        choices: choice,
      });
    }
    return arr;
  };
  let idxx = -1;
  const classes = useStyles();

  return (
    <div>
      <Card
        container
        sx={{
          overflow: "auto",
        }}
      >
        <table className={classes.TableStyle}>
          <thead>
            <tr>
              {renderHeader().map((item) => {
                return <th className={classes.HeaderStyle}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {renderOptions().map((item, i) => (
              <td className={classes.DataStyle}>
                {item.choices.map((option) => {
                  return (
                    <Grid container>
                      <Grid item md={10}>
                        &nbsp;  &nbsp;
                        <FormControlLabel
                         sx={{alignItems:"center"}}
                          control={
                            <Checkbox
                              name='optionA'
                              color='primary'
                              checked={selectedChoice?.some(
                                (selected) =>
                                  selected.bundleNo === item.bundleNo &&
                                  selected.id === option.id
                              )}
                            />
                          }
                          label={
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <Box display={"flex"}>
                                {`(${alphaOption[(idxx += 1)]}) `}
                              </Box>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${option.text}`,
                                }}
                                variant='body1'
                                // style={{ color: "#2D6CDF" }}
                                className={classes.questHead}
                              />
                            </div>
                          }
                          onChange={() => {
                            onChange(item, option);
                          }}
                        />
                        {/* <tr>
                          <IconButton>
                            <Radio className={classes.RadioStyle} />
                          </IconButton>
                          {`(${alphaOption[(idxx += 1)]}) ${option.text}`}
                        </tr> */}
                      </Grid>
                    </Grid>
                  );
                })}
              </td>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default Bundle;