import React from 'react'

export const Calendar = () => {
  return (
    <svg width="25" height="29"  viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.6668 0.333252H10.3335C9.60013 0.333252 9.00013 0.933252 9.00013 1.66659C9.00013 2.39992 9.60013 2.99992 10.3335 2.99992H15.6668C16.4001 2.99992 17.0001 2.39992 17.0001 1.66659C17.0001 0.933252 16.4001 0.333252 15.6668 0.333252ZM13.0001 17.6666C13.7335 17.6666 14.3335 17.0666 14.3335 16.3333V10.9999C14.3335 10.2666 13.7335 9.66659 13.0001 9.66659C12.2668 9.66659 11.6668 10.2666 11.6668 10.9999V16.3333C11.6668 17.0666 12.2668 17.6666 13.0001 17.6666ZM22.3735 8.85325L23.3735 7.85325C23.8801 7.34659 23.8935 6.50659 23.3735 5.98659L23.3601 5.97325C22.8401 5.45325 22.0135 5.46659 21.4935 5.97325L20.4935 6.97325C18.4268 5.31992 15.8268 4.33325 13.0001 4.33325C6.60013 4.33325 1.16013 9.61325 1.00013 16.0133C0.8268 22.7866 6.25347 28.3333 13.0001 28.3333C19.6401 28.3333 25.0001 22.9599 25.0001 16.3333C25.0001 13.5066 24.0135 10.9066 22.3735 8.85325ZM13.0001 25.6666C7.84013 25.6666 3.6668 21.4933 3.6668 16.3333C3.6668 11.1733 7.84013 6.99992 13.0001 6.99992C18.1601 6.99992 22.3335 11.1733 22.3335 16.3333C22.3335 21.4933 18.1601 25.6666 13.0001 25.6666Z" fill="#2D6CDF"/>
</svg>

  )
}
