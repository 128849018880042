import React from 'react';
import Typo from '../typo/Typo';
import { Card, Grid } from '@mui/material';
import { data } from '../data/data';
import { useStyles } from './Style';

export const QuestionComponent = (data) => {
  const classes = useStyles();

  return (
    <Card className={classes.questCard}>
      <Grid container spacing={1} padding={3}>
        <Grid item md={12}>
          <Typo variant='body1' color='#2D6CDF' fontWeight='600' className={classes.questHead}>
            Question {data?.currentQuestion}
          </Typo>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data && data?.imageUrl
                  ? `${data?.question}<br><br><br><img src=${data?.imageUrl} />`
                  : `${data?.question}`,
            }}
            variant='body1'
            className={classes.questHead}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
