import React from 'react'

export const FolderAdd = () => {
  return (
    <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8441 12.587H2.90503V8.56033C2.90503 5.30699 5.70743 2.66699 9.16089 2.66699H12.4587C14.7657 2.66699 15.4875 3.37366 16.4075 4.53366L18.389 7.01366C18.8277 7.56033 18.8844 7.64033 19.7053 7.64033H23.6541C27.0085 7.62699 29.8675 9.70699 30.8441 12.587Z" fill="#22D1EE"/>
<path d="M31.1979 14.4536C31.1696 13.8002 31.0564 13.1869 30.8441 12.5869H2.90503V22.2002C2.90503 26.1336 6.30188 29.3336 10.4772 29.3336H23.6399C27.8152 29.3336 31.2121 26.1336 31.2121 22.2002V14.7602C31.2121 14.6669 31.2121 14.5469 31.1979 14.4536ZM20.5969 21.6669H18.205V24.0002C18.205 24.5469 17.7238 25.0002 17.1435 25.0002C16.5632 25.0002 16.082 24.5469 16.082 24.0002V21.6669H13.5202C12.9399 21.6669 12.4587 21.2136 12.4587 20.6669C12.4587 20.1202 12.9399 19.6669 13.5202 19.6669H16.082V17.3336C16.082 16.7869 16.5632 16.3336 17.1435 16.3336C17.7238 16.3336 18.205 16.7869 18.205 17.3336V19.6669H20.5969C21.1772 19.6669 21.6584 20.1202 21.6584 20.6669C21.6584 21.2136 21.1772 21.6669 20.5969 21.6669Z" fill="#2D6CDF"/>
</svg>

  )
}