import Button from "@mui/material/Button";
import styled from "styled-components";

const CustomPrimaryButton = styled(Button)`
  box-shadow: 0px 12px 24px rgba(23, 86, 255, 0.4) !important;
  background: linear-gradient(91deg, #2d6cdf 4.75%, #6757d8 99.26%) !important;
  border-radius: 8px !important;
  color: #fff !important;
  text-transform: uppercase !important;
  text-align: center !important;
  font-size: 16px !important;
  width: ${(props) => `${props.minWidth} !important`};
  height: 50px !important;
  justify-content: space-around !important;
  font-weight: 900px !important;
`;

const CustomOutlinedButton = styled(Button)`
  border: 1px solid linear-gradient(91deg, #2d6cdf 4.75%, #6757d8 99.26%) !important;
  color: linear-gradient(91deg, #2d6cdf 4.75%, #6757d8 99.26%) !important;
  border-radius: 8px !important;
  text-transform: uppercase !important;
  text-align: center !important;
  font-size: 16px !important;
  width: ${(props) => `${props.minWidth} !important`};
  height: 50px !important;
  justify-content: space-around !important;
  font-weight: 900px !important;
`;




const CustomRetryButton = styled(Button)`
  border: 1px solid  !important;
  background: #fff !important ;
  border-radius: 8px !important;
  color: linear-gradient(91deg, #2D6CDF 4.75%, #6757D8 99.26%) !important;
  text-transform: uppercase !important;
  text-align: center !important;
  font-size: 16px !important;
  width: ${(props) => `${props.minWidth} !important`};
  height: 50px !important;
  justify-content: space-around !important;
  font-weight: 900px !important;
`;



export { CustomPrimaryButton, CustomOutlinedButton ,CustomRetryButton};
