import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  InstructionFullPart: {
    height: "100%",
    flexDirection: "column",
    paddingLeft: "5%",
    background:
      " linear-gradient(180deg, #DDE9FF 7%, rgba(221, 233, 255, 0) 20%)",
  },
  ScienceTestStyle: {
    padding: 15,
    paddingLeft: 20,
    fontWeight: 600,
    // fontSize: "32px",
    color: "#354354",
  },
  ScienceTestCont: {
    lineHeight: "24px",
    fontSize: "12px",
    paddingTop: "25px",
  },
  CardContainer: {
    // paddingLeft: 22,
    paddingTop: 30,
  },
  CardStyle: {
    width: "95%",
    alignSelf: "center",
    padding: "2%",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)",
    borderRadius: " 12px",
  },
  QuestionsStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
  SectionsStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
  instruction: {
    display: "flex",
    gap: "8px",
    // alignItems: "center",
  },
  getStart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "25px",
  },
  divider: {
    padding: 12,
    color: "#C4C4C4",
  },
  cardpart: {
    // padding: 40,
    paddingTop: "20px",
    paddingBottom: "20px",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)",
    borderRadius: "12px",
  },
  cardcontain: {
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "center",
    // backgroundColor: "red",
    color: " #5B656F",
  },
  section: {
    display: "flex",
    paddingLeft: "1%",
  },
  innerdivision: {
    display: "flex",
    // paddingRight: "40%",
    marginTop: 10,
    marginLeft: 10,
    fontWeight: "600",
  },
  dividerItem: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  testHead: {
    paddingTop: 20,
    paddingBottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  instructionShadow: {
    background:
      "linear-gradient(180deg, #DDE9FF 7%, rgba(221, 233, 255, 0) 20%)",
  },
  dividerstyle: {
    height: "100%",
    width: "1px",
    backgroundColor: "#C4C4C4",
  },
  durationstyle: {
    display: "flex",
    flexDirection: "row",
  },
  "@media (max-width: 600px)": {
    getStartButtonTestProct: {
      position: "relative",
      left: "43px",
    },
    getIcon: {
      position: "relative",
      left: "15px",
    },
    StaticBtn: {
      position: "relative",
      // bottom: "30px !important",
    },
    videoBoxTestProct: {
      position: "relative",
      bottom: "20px",
    },
    webAlign: {
      marginBottom: "35px",
      height: "100%",
      width: "200% !important",
      borderRadius: "8px !important",
      display: "flex",
      justifyContent: "center",
    },
    // static__button_wrapper: {
    //   position: "relative",
    //   bottom: "20px",
    // },
    mobDivAlign: {},

    getIconBtn: {
      position: "relative",
      left: "30px",
    },
    testHeaderProcter: {
      marginTop: "60px !important",
    },
  },

  // getStartButtonTestProct: {
  //   position: "relative",
  //   left: "30px",
  // },
  buttonWrapper: ({ mediaQuery }) => ({
    textAlign: "center",
    padding: "0 12px",
    paddingBottom: mediaQuery ? 80 : 22,
  }),
  staticButtonContainer: ({ mediaQuery }) => ({
    position: "relative",
    display: "block",
    minHeight: `${50 + (mediaQuery ? 80 : 22)}px`,
    "& .static__button_wrapper": {
      position: "fixed",
      left: "15px",
      right: "15px",
      bottom: 0,
    },
  }),
}));