import React, { useState } from "react";
import DropzoneComponent from "./DropzoneComponent";
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useStyles } from "./Style";
import PrimaryButton from "../primary-button/PrimaryButton";
import { Navigate } from "../iconsJS/Navigate";
import Typo from "../typo/Typo";
import useSnack from "../../hooks/useSnack";
import { useEffect, useRef } from "react";
import { dispatch } from "../../redux/Store";
import { styled } from "@mui/material/styles";
import { uploadVideo } from "../../redux/slices/Test";
import LinearDeterminate from "../ProgressBar/Progress";
import { Fileicon } from "../iconsJS/File";
import CustomizedSnackbars from "../snack/snack";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileDeleteOpt } from "../../redux/slices/Test";

export const AnswerFile = ({ setFilePath, filePath, RecordingResponse }) => {
  useEffect(() => {
    if (!filePath) {
      setProgress(0);
      setIsSubmit(false);
      setIsProgress(false);
    }
  }, [filePath]);
  // const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const [filesize, setFilesize] = useState(null);

  const [file, setFile] = useState("");
  const [finalFile, setFinalFile] = useState(null);
  const [message1, setMessage1] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [filenew, setFilenew] = useState(false);
  const [fileNameShow, setFileNameShow] = useState("");
  const [fileneww, setFileneww] = useState(false);
  const [filename, setFilename] = useState("");
  const [check, setCheck] = useState(false);
  const [deletestate, setDeletestate] = useState(false);
  const { open, setOpen, handleClose, message, setMessage, color, setColor } =
    useSnack();
  const DeleteFile = () => {
    console.log("clicked");
    // console.log(questionData.id);

    dispatch(
      FileDeleteOpt(filename, (response) => {
        if (response.success) {
          setDeletestate(true);
          // setOpen(true);
        }
      })
    );
    // if (FileDeleteOpt.success) {
    //   console.log("true");
    // } else {
    //   console.log("false");
    // }
  };

  const getRecordingFileHooks = async () => {
    if (file === "") {
      setIsProgress(false);
      setOpen(true);
      setMessage("Please upload file");
      setColor("error");
    }
    console.log(file);
    console.log(file.length);
    const length1 = file.length - 3;
    const length2 = file.length;

    console.log(file.slice(length1, length2));
    const filecheck = file.slice(length1, length2);
    // if(filecheck === "pdf")
    // {

    var name = "File";
    var blob = new Blob([file], { type: "File/pdf" });
    console.log(file);

    // if (filesize > 2e6) {
    //   setOpen(true);
    //   setMessage("Accept Maximum limit 2 MB ");
    //   setColor("error");

    if (filecheck !== "pdf") {
      setOpen(true);
      setMessage("Please upload a file in PDF format");
      setFile("");
      setCheck(true);
      setFileNameShow("");
      setColor("error");
    }
    // }
    else {
      console.log(file);
      // setOpen(false);
      // var name = "recordedFile";
      // var indexOf = file.type.indexOf("/");

      // var newFileType = file.type.substr(indexOf + 1);

      var newFile = new File(
        [blob],
        file,
        // name.concat(".", newFileType).replace("pdf", "pdf"),
        { type: "file/pdf" }
      );
      setFinalFile(newFile);

      console.log(finalFile);
      console.log(FormData);
      const fileData = new FormData();
      fileData.append("file", newFile);
      console.log(newFile);

      if (filesize > 2e6) {
        setOpen(true);
        setMessage("Accept Maximum limit 2 MB ");
        setFile("");
        setColor("error");
      } else {
        if (file !== "") {
          dispatch(
            uploadVideo(fileData, onUploadProgress, (res) => {
              if (res.success) {
                setFile("");
                setMessage1(null);
                setFilenew(true);
                setFileneww(true);
                setDeletestate(false);

                if (setFilePath) setFilePath(res.data);
              } else {
                setMessage1(res.message1);
                setIsProgress(false);
                setProgress(0);
              }
              RecordingResponse(res);
            })
          );
        }
      }
    }
    // }
    // else{
    //   setOpen(true);
    //   setColor("error");
    //   setMessage("Please upload a file in pdf format!!")

    // }
  };
  const fileData = new FormData();
  const onUploadProgress = (progressEvent) => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader("content-length") ||
        progressEvent.target.getResponseHeader("x-decompressed-content-length");
    if (totalLength !== null) {
      setIsProgress(true);

      setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
    }
  };

  const handleDrop = (newFile) => {
    if (newFile) {
      const newDataName = newFile?.[0]?.name;
      console.log(newFile?.[0].name);
      const fileSize = newFile?.[0]?.size;
      setFilesize(fileSize);
      console.log(fileSize);
      setFile(newDataName);
      // const fileSize = newFile?.[0]?.size;
      // console.log(fileSize);

      setFileNameShow(newDataName);
    } else {
      setFile(null);
    }
  };
  // console.log(file?.path);
  console.log(file?.length);
  // console.log(file?.name);

  return (
    <>
      <div>
        {isProgress === false || deletestate === true ? (
          <div>
            <Typo style={{ color: "#2D6CDF", paddingLeft: "40px" }}>
              {file}
            </Typo>
            <DropzoneComponent onDrop={handleDrop} acceptTypes={".pdf"} />
            <Grid container alignItems="center" justifyContent="center">
              <Grid item className={classes.buttonUpload}>
                <PrimaryButton
                  width={!mediaQuery ? "100px" : "100px"}
                  height={"40px"}
                  onClick={getRecordingFileHooks}
                >
                  <b style={{ fontSize: "14px" }}>Upload</b>
                  <Navigate />
                </PrimaryButton>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Card className={classes.questCard}>
            {" "}
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Fileicon /> {fileNameShow}
              <DeleteIcon color="success" onClick={DeleteFile} />
            </div>
            <div>
              <LinearDeterminate value={progress} />
              <Typo
                variant="h5"
                color="#32CD32"
                style={{ textAlign: "center" }}
              >
                {progress === 100 && "Upload successful"}
              </Typo>
            </div>
          </Card>
        )}
        <CustomizedSnackbars
          open={open}
          handleClose={handleClose}
          text={message}
          type={color}
        />
      </div>
      {/* <CustomizedSnackbars
        // style={{display:"flex",alignSelf:"center",marginLeft:300}}
        open={open}
        handleClose={() => setOpen(false)}
        type={"error"}
        text={"File Deleted Successfully"}
      /> */}
    </>
  );
};
