import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "flex",
    boxShadow: "4px 4px 18px 1px rgba(146, 170, 212,0.2)",
    justifyContent: "space-around",
    position: "fixed",
    // left: 0,
    // right: 0,
    // top: 0,
  },

  // clImg: {
  //   objectFit: "contain",
  //   width: "186px",
  // },
  headerIcons: {
    display: "flex",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  Avatar: {
    width: "55px",
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    clImg: {
      objectFit: "contain",
      width: "107px",
      height: "38px",
    },
    Avatar: {
      width: "50px",
      height: "30px",
    },
  },

  "@media (max-width: 600px)": {
    headingAlignFixed: {
      position: "fixed !important",
      top: "0 !important",
      backgroundColor: "white !important",
      zIndex: "1100 !important",
    },
  },
}));
