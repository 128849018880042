import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: ${(props) => props.padding};

  @media (max-width: 768px) {
    ::-webkit-scrollbar,
    * ::-webkit-scrollbar {
      display: none;
    }
  }
`;
