import Cookies from 'js-cookie';

const SETTER = {
  sessionSetter: (name, value) => sessionStorage.setItem(name, value),
  cookieSetter: (name, value, options) => Cookies.set(name, value, options),
};

export const removeCookie = (name, options) => Cookies.remove(name, options);

const GETTER = {
  sessionGetter: (name) => sessionStorage.getItem(name),
  cookieGetter: (name) => Cookies.get(name),
};

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function generateString(length) {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const studentId = GETTER.cookieGetter('studentId');
// const studentId = '8536d81a-8e50-4211-b73f-52e432ff232e';
const testQuestionSetId = GETTER.cookieGetter('testQuestionSetId');
// const testQuestionSetId = '2c5ba8f5-163a-4d3f-a80f-2855a10f0a21';
//const testQuestionSetId = "583a444a-c5bc-4409-a304-88e25b73f690";
//const studentId =
// "109e93fc-00d8-41ab-9177-698136d95443";
// GETTER.cookieGetter("studentId");
//const studentId = "ca97db96-a8f4-46fb-be6d-5819613bf163";
// // = generateString(8)
// // "dbcjekkgftppppppppppppppppsdhjgweghiipkk";

const testExeId = '500ff1e0-e53f-44b6-8dbb-cad021f3162e';
const accessToken =
  //"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2NzEzNjczMjY3IiwiZXhwIjoxNjYxODU4MzQ1LCJpYXQiOjE2NjE4NTExNDV9.ML88SVzCXbSmWYK6d1spKRBPcq3x3_uNwTGEYcGqxUmkrqLIG5DpwJp6cntBkAZuKHcZe5dbsXupsXzvhFrc6w";
  // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI0ODA5ODk0ODQ4IiwiZXhwIjoxNjYxODQwNzA3LCJpYXQiOjE2NjE4MzM1MDd9.U3ybG5_olkrUGe18I57tLAyzf8_sHif7UjG8O-ERm9ZUbsFtnylI95dTz0MFm2IFJI_p5VKf2d5LsQU9gchH1w";
  // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2NjczNjczMjMzIiwiZXhwIjoxNjYxNzgzNTUxLCJpYXQiOjE2NjE3NzYzNTF9.WCcRESaYwkqafpW6zKCGvoAdTBtRUmr5QuWcf9fBRkecftu9xPoqZxZo2_FWFmQYgrz91sON7ls2LYHkHR52Hg";
  // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4NzMyODc2MzQ2IiwiZXhwIjoxNjYxNzcyMDkxLCJpYXQiOjE2NjE3NjQ4OTF9.2Fd1wJE5LzC6I5fVjQ90eTHTBG2k5M991upGMHBSgFNWzOMFD9q88hvFSV5uG8033-dZrNvHLiXf3FF820EgaQ";
  // 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzODczODgzOTM5IiwiZXhwIjoxNjg1MDIyMTA1LCJpYXQiOjE2ODUwMTQ5MDV9.n4luxX5UW2slX0Z6lXtpoAdYKgjBLl2dYMf-Zg8rszUdcqaOZMKJ2a5W2ZhlDHKE9agslUuptD7lg9Ql6gtkLQ';
  GETTER.cookieGetter('accessToken');

export { testQuestionSetId, studentId, GETTER, SETTER, testExeId, accessToken };
