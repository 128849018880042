import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  GridStyle: {
    paddingBottom: "6px",
  },
  TableStyle: {
    width: "100%",
    border: "0.5px solid #C4C4C4",
    borderCollapse: "collapse",
  },
  HeadStyle: {
    paddingborderCollapse: "collapse",
    borderSizing: "border-box",
    border: "0.5px solid #C4C4C4",
  },
  DataStyle: {
    borderCollapse: "collapse",
    borderSizing: "border-box",
    border: "0.5px solid #C4C4C4",
  },
  RadioStyle: {
    alignContent: "center",
  },
  questHead: {
    "& figure": {
      width: "100% !important",
      overflow: "hidden",
    },
    "& img": {
      width: "auto !important",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
}));
