import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
  return (
    <Snackbar
      // sx={{ height: "100%" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={props.open}
      autoHideDuration={3000}
      onClose={props.handleClose}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "center",
      // }}


    >
      <Alert
        onClose={props.handleClose}
        severity={props.type}
        sx={{ width: "100%" }}

      >
        {props.text}
      </Alert>
    </Snackbar>
  );
}
