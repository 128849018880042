import React from "react";
import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@material-ui/core";

import { useStyles } from "../Instruction/Styles";
export const IconSymbol = () => {
  // const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useStyles();
  // const theme = useTheme();

  return (
    <svg
      className={classes.getIconBtn}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66641 4.74161L5.02474 3.09994L2.34974 0.424939C1.78307 -0.133394 0.816406 0.266606 0.816406 1.06661V6.25827V10.9333C0.816406 11.7333 1.78307 12.1333 2.34974 11.5666L6.66641 7.24994C7.35807 6.56661 7.35807 5.43327 6.66641 4.74161Z"
        fill="white"
      />
    </svg>
  );
};
