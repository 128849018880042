export const data = {
  question: `<p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">If&nbsp;</span><math xmlns="http://www.w3.org/1998/Math/MathML"><mi>x</mi><mo>+</mo><mi>y</mi><mo>=</mo><mn>9</mn></math><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">&nbsp;and 3</span><math xmlns="http://www.w3.org/1998/Math/MathML"><msup><mi>x</mi><mn>2</mn></msup><mo>+</mo><msup><mi>y</mi><mn>2</mn></msup><mo>=</mo><mn>53</mn></math><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">, where&nbsp;</span><math xmlns="http://www.w3.org/1998/Math/MathML"><mi>x</mi><mo>&gt;</mo><mi>y</mi></math><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">, what is the value of&nbsp;</span><math xmlns="http://www.w3.org/1998/Math/MathML"><mo stretchy="false">(</mo><mi>x</mi><mo>−</mo><mi>y</mi><mo stretchy="false">)</mo></math><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);">?</span></p>`,
  choices: [
    {
      id: "66d01d58-1dc4-4e09-b26f-65a78faac01c",
      type: "text",
      text: "<p>empathy</p>",
      choiceImage: null,
      bundleNo: 2,
      orderNo: 1,
    },
    {
      id: "6b8f672c-2d03-4175-816b-2e4f3a86e19b",
      type: "text",
      text: "<p>all</p>",
      choiceImage: null,
      bundleNo: 1,
      orderNo: 1,
    },
    {
      id: "172d5eea-e975-4130-8a83-f1666bd48d93",
      type: "text",
      text: "<p>dear</p>",
      choiceImage: null,
      bundleNo: 3,
      orderNo: 1,
    },
    {
      id: "02df41af-86a9-4a37-bb2f-f06de5431711",
      type: "text",
      text: "concurrence",
      choiceImage: null,
      bundleNo: 2,
      orderNo: 2,
    },
    {
      id: "91f8bcb4-a461-41b5-9688-f22ede7a3522",
      type: "text",
      text: "<p>balaji</p>",
      choiceImage: null,
      bundleNo: 1,
      orderNo: 2,
    },
    {
      id: "beb35a56-42a6-4923-b33c-6d3c8c7a4cc6",
      type: "text",
      text: "<p>mii</p>",
      choiceImage: null,
      bundleNo: 3,
      orderNo: 2,
    },
    {
      id: "eca2eec7-8331-40b4-8d97-00b74f1cc5f0",
      type: "text",
      text: "<p>hello</p>",
      choiceImage: null,
      bundleNo: 1,
      orderNo: 3,
    },
    {
      id: "ddf51c0a-e984-419f-bf4f-d16d3b8f63fa",
      type: "text",
      text: " <p>all</p>",
      choiceImage: null,
      bundleNo: 3,
      orderNo: 3,
    },
    {
      id: "fc1def25-7fa3-498a-9409-10457cfa582e",
      type: "text",
      text: "<p>agsag</p>",
      choiceImage: null,
      bundleNo: 2,
      orderNo: 3,
    },
  ],
  type: "BUNDLE",
};
