import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  "@media (max-width:600px)": {
    testHeaderItemAlign: {
      marginTop: "30px !important",
    },
    questionTextStyle: {
      marginTop: "-23px !important",
    },
  },
  QuestAns: {
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
  },
  questStyle: {
    padding: 30,
    // marginBottom: 50,
  },
  calendar: {
    display: "flex",
  },
  webcam: {
    display: "flex",
  },
  contentWrapStyle: {
    "& figure": {
      width: "100% !important",
      overflow: "hidden",
    },
    "& img": {
      width: "auto !important",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
  questionTextStyle: {
    // paddingLeft: "0 !important",
  },
  // timeManage: {
  //   fontWeight: "bold !important",
  // },
  // snack:{

  //   display:"flex",
  //   justifyContent:"center",
  //   marginLeft:30,
  //   textAlign:"center",
  // },
  name: {
    fontWeight: "bold",
  },
}));
