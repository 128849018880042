import { makeStyles } from "@mui/styles";
import React from "react";
import { useMediaQuery } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  "@media (max-width: 600px)": {
    footerPart: {
      backgroundColor: "#251D63",
      // padding: 15,
      padding: "7px !important",
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
      marginTop: 1,
      marginBottom: 0,
      // position: "static",
      // bottom: 0,
      // width: "100%",
      position: "fixed",
      left: "0px",
      right: "0px",
      bottom: 0,
    },
    footerPadding: {
      position: "relative",
      bottom: "7px",
    },
    // footItem: {
    //   backgroundColor: "red",
    // },
    // iconName: {
    //   position: "relative",
    //   left: "5px",
    // },
  },
  // "@media (max-width: 600px)": {
  //   footerPart: {
  //     padding: "15px !important",
  //   },
  // },
  "@media (max-width: 1200px)": {
    footerPart: {
      backgroundColor: "#251D63",
      padding: 20,
      paddingLeft: 30,
      paddingRight: 30,
      marginTop: 1,
      // marginBottom: 30,
    },
  },
  endtestButton: {
    fontFamily: "Poppins",
    padding: "1px",
  },
  footerPart: {
    backgroundColor: "#251D63",
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 1,
  },
  footerStyle: {
    bottom: 0,
  },
  // footItem: {
  //   backgroundColor: "red",
  // },
  container: {
    display: "flex",
    justifyContent: "space-between",
    background: "#251D63",
    padding: "10px",
    paddingLeft: "20px",
  },
  ExitStyle: {
    paddingLeft: "35px",
  },
  NextStyle: {
    paddingRight: "35px",
  },
  ProgressStyle: {
    padding: 10,
  },

  ordertwo: {
    display: "flex",
    padding: 10,
    // paddingLeft: 50,
    justifyContent: "center",
  },
  // footitem: {
  //   backgroundColor: "yellow",
  // },
  popupModel: {
    borderRadius: 15,
  },
  footerPadding: {
    position: "relative",
    bottom: "6px",
  },
}));
