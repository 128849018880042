import React, { useState } from "react";

function useSnack() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('')
  const [color, setColor] = useState('');

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return {
    open,
    setOpen,
    handleClose,
    message,
    setMessage,
    color,
    setColor
  };
}

export default useSnack;
