import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Typo from "../typo/Typo";
import { FolderAdd } from "../iconsJS/folderAdd";
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useStyles } from "./Style";
import PrimaryButton from "../primary-button/PrimaryButton";
import { Navigate } from "../iconsJS/Navigate";
import { useEffect, useState, useRef } from "react";
const borderImage = (color) => {
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23${color}FF' stroke-width='3' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`;
};

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "26px 12px 42px",
  transition: "border .3s ease-in-out",
  background: "#ffffff",
  backgroundImage: borderImage("ACC1E8"),
  borderRadius: "8px",
  gap: "7px",
  cursor: "move",
  overflow: "hidden",
  outline: "none",
};

const activeStyle = {
  backgroundImage: borderImage("2196F3"),
};

const acceptStyle = {
  backgroundImage: borderImage("00E676"),
};

const rejectStyle = {
  backgroundImage: borderImage("FF1744"),
};

function DropzoneComponent({ acceptTypes, onDrop }) {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: onDrop,
    accept: acceptTypes,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  // const [progress, setProgress] = useState(0);
  // const [isProgress, setIsProgress] = useState(false);
  // const [isSubmit, setIsSubmit] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const hiddenFileInput = React.useRef(null);
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <section style={{ margin: "20px" }}>
      <Card className={classes.questCard}>
        <div {...getRootProps({ style })} onClick={handleClick}>
          <input
            type="file"
            style={{ display: "none" }}
            ref={hiddenFileInput}
            {...getInputProps}
            accept={acceptTypes}
            onChange={(e) => onDrop(e.target.files)}
          ></input>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ flexFlow: "initial" }}
          >
            <Grid item>
              <FolderAdd />
            </Grid>
            <Grid item className={classes.typoGrid}>
              <Typo
                variant="body1"
                className={[classes.typoTitle, classes.typo]}
              >
                {"Attach your files"}{" "}
                <span style={{ color: "#2D6CDF" }}>{"here"}</span>
              </Typo>
              <Typo
                variant="body2"
                className={[classes.typoSubTitle, classes.typo]}
              >
                {"Upload your file in PDF  file format  - Max. 2 MB"}
              </Typo>
            </Grid>
          </Grid>
        </div>
      </Card>
    </section>
  );
}

export default DropzoneComponent;
