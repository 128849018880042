import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  popupCard: {
    display: "flex",
    justifyContent: "center",
    // width: "110%",
    borderRadius: "18px",
    overflowX: "hidden",

    // padding: 3,
  },
  popup: {
    display: "flex",
    justifyContent: "center",
  },
  popupContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 30,
  },
  Dialog: {
    display: "flex",
    justifyContent: "center",
    // marginLeft: 40,
    alignItems: "center",
  },
  PopupCardAlign: {
    overflowX: "hidden",
  },
  dialogCont: {
    display: "flex",
    justifyContent: "center",
    color: "#354354",
    fontSize: "24px",
    marginBottom: "30px",
  },
  dialogButton: {
    display: "flex",
    justifyContent: "space-around",
    padding: 10,
  },
  popupText: {
    display: "flex",
    justifyContent: "center",
    color: "#354354",
  },
  buttonResponse: {
    display: "flex",
  },
  [theme.breakpoints.down("md")]: {
    dialogCont: {
      fontSize: "18px",
    },
  },
  "@media (max-width:600px)": {
    popupText: {
      position: "relative",
      top: "20px !important",
    },
    popupContainer: {
      display: "flex",
      justifyContent: "center",
      padding: 20,
    },
    // dialogCont: {
    //   position: "relative",
    //   top: "20px !important",
    // },
  },
}));
