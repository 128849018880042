import { Grid } from "@mui/material";
import React from "react";
import DatePicker from "../common-components/DatePicker";
import InputField from "../common-components/InputField";
import DropDown from "../common-components/DropDown";
import { CustomTypo, InputBox, useStyles, ErrorMsg } from "./Styles";
import { isNumber } from "../../utils/Validation";
import CreatableDropdown from "../common-components/CreatableDropdown";
import { createFilterOptions } from "@material-ui/lab";

const filter = createFilterOptions();

function UgInfo({
  degreeOption,
  departmentOption,
  college,
  degree,
  handleChange,
  handleInputChange,
  branch,
  collegeName,
  backlogNumber,
  activeBacklogs,
  yearOfPassing,
  handlePickerChange,
  ugPercentage,
  getFormDetails,
  errorMsg,
  allState,
  handleChangeDropdown,
}) {
  const classes = useStyles();
  const exceptThisSymbols = ["e", "E", "+", "-"];
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <CustomTypo variant={"body2"} className={classes.sectionText}>
          {"Section-3"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <CustomTypo variant={"h6"} color={"#354354"} fontWeight={600}>
          {"Undergraduate Information"}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2.75} pb={2.75}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"UG College Name "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <CreatableDropdown
                    id={"no-outline"}
                    name={"ug_college"}
                    value={collegeName || null}
                    onChange={handleChangeDropdown}
                    optionLabel={"name"}
                    options={college || []}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.push({
                          id: null,
                          name: params.inputValue,
                        });
                      }
                      return filtered;
                    }}
                    textProps={{
                      placeholder: "Select UG college name",
                      required: true,
                    }}
                  />
                </InputBox>
                {collegeName ? null : (
                  <ErrorMsg>{errorMsg.collegeName}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"UG Degree "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={degreeOption || []}
                    getOptionLabel={(item) => item.name}
                    value={degree}
                    onChange={(e, newValue) =>
                      handleInputChange("degree", newValue)
                    }
                    disabled={getFormDetails?.ugDegree?.name ? true : false}
                    placeholder={"Select UG degree"}
                    hasNoBorder
                  />
                </InputBox>
                {degree ? null : <ErrorMsg>{errorMsg.degree}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"UG Branch "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DropDown
                    options={departmentOption || []}
                    getOptionLabel={(item) => item.name}
                    placeholder={"Select UG branch"}
                    hasNoBorder
                    value={branch}
                    disabled={getFormDetails?.ugDepartment?.name ? true : false}
                    onChange={(e, newValue) =>
                      handleInputChange("branch", newValue)
                    }
                  />
                </InputBox>
                {branch ? null : <ErrorMsg>{errorMsg.branch}</ErrorMsg>}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"UG Pass-out year "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <DatePicker
                    value={yearOfPassing}
                    views={["year", "month"]}
                    placeholder={"Enter UG Pass-out year"}
                    onChange={(e) => handlePickerChange(e)}
                    hasNoBorder
                    name={"yearOfPassing"}
                    // disableFuture
                    disabled={getFormDetails?.ugEndDate ? true : false}
                  />
                </InputBox>
                {yearOfPassing ? null : (
                  <ErrorMsg>{errorMsg.yearOfPassing}</ErrorMsg>
                )}
                {yearOfPassing &&
                new Date(allState?.twelthPassingYear)?.getFullYear() >
                  new Date(allState?.yearOfPassing)?.getFullYear() ? (
                  <ErrorMsg>{errorMsg.validYearOfPassing}</ErrorMsg>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"Number of active backlogs (if any) "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    // type={'number'}
                    autoComplete="off"
                    placeholder={"Enter no. of active backlogs"}
                    value={activeBacklogs}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ min: 0 }}
                    name={"activeBacklogs"}
                    onKeyPress={(evt) => {
                      if (isNumber(evt) || evt.target.value.length > 1) {
                        evt.preventDefault();
                      }
                    }}
                    disabled={
                      getFormDetails?.activeBacklogs !== null ? true : false
                    }
                    hasNoBorder
                  />
                </InputBox>
                {activeBacklogs ? null : (
                  <ErrorMsg>{errorMsg.activeBacklogs}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"History of backlogs (if any) "}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    // type={'number'}
                    autoComplete="off"
                    placeholder={"Enter History of backlogs"}
                    value={backlogNumber}
                    onChange={(e) => handleChange(e)}
                    inputProps={{ min: 0 }}
                    hasNoBorder
                    disabled={
                      getFormDetails?.clearedBacklogs !== null ? true : false
                    }
                    name={"backlogNumber"}
                    onKeyPress={(evt) => {
                      if (isNumber(evt) || evt.target.value.length > 1) {
                        evt.preventDefault();
                      }
                    }}
                  />
                </InputBox>
                {backlogNumber ? null : (
                  <ErrorMsg>{errorMsg.clearedBacklogs}</ErrorMsg>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTypo
                  color={"#828D98"}
                  variant={"caption"}
                  fontWeight={500}
                >
                  {"UG % (out of 100)"}
                  <CustomTypo
                    fontSize={"14px"}
                    color={"#FF0000"}
                    variant={"caption"}
                    fontWeight={500}
                  >
                    {"*"}
                  </CustomTypo>
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={"number"}
                    name={"ugPercentage"}
                    placeholder={"Enter UG Percentage"}
                    autoComplete="off"
                    onChange={(evt) => {
                      // let num = Number(evt.target.value);
                      if (evt.target.value > 100 || evt.target.value === "0") {
                        evt.preventDefault();
                      } else {
                        handleChange(evt);
                      }
                    }}
                    inputProps={{ min: 1, max: 100 }}
                    hasNoBorder
                    value={ugPercentage}
                    onKeyDown={(evt) => {
                      let num = evt.target.value;
                      if (
                        (num.length === 0 && evt.which === 190) ||
                        exceptThisSymbols.includes(evt.key)
                      ) {
                        evt.preventDefault();
                      }
                    }}
                    disabled={getFormDetails?.ugPercentage ? true : false}
                  />
                </InputBox>
                <CustomTypo
                  color={"#828D98"}
                  fontWeight={500}
                  fontSize={10}
                  paddingTop={"7px"}
                  paddingLeft={"5px"}
                >
                  {"Convert CGPA in % as per your university conversion scale."}
                </CustomTypo>
                {ugPercentage ? null : (
                  <ErrorMsg>{errorMsg.ugPercentage}</ErrorMsg>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UgInfo;
