import React from "react";
import { Card, DialogActions } from "@mui/material";
import OutlinedButton from "../primary-button/OutlinedButton";
import PrimaryButton from "../primary-button/PrimaryButton";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Typo from "../typo/Typo";
import { useStyles } from "./Style";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@material-ui/core";
import "../../components/popup/popup.css";
import { styled } from "@mui/material/styles";
const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.up("xs")]: {
    // width: "100%",
    // paddingLeft: "6rem",
  },
}));
const ContinueBut = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // paddingLeft:"2rem",
    // paddingLeft: "-5rem",
  },
}));
const ExitBut = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

export const PopupCard = ({ close, endExam }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <Grid item md={12}>
        <div className={classes.PopupCardAlign}>
          <div className={classes.popup}>
            <Grid item md={12} xs={12} sm={12}>
              <Card className={classes.popupCard}>
                <Container className={classes.popupContainer}>
                  <Grid container md={12} xs={12} className={classes.Dialog}>
                    <div className="popupLetters">
                      <Grid item md={12} xs={12} sm={12}>
                        <Typo
                          //  fontSize={24}
                          variant="body3"
                          fontWeight={600}
                          className={classes.dialogCont}
                          id="contentId"
                        >
                          Are you sure you want to
                        </Typo>
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typo
                          variant="body3"
                          fontWeight={500}
                          className={classes.dialogCont}
                          id="contentId_2"
                        >
                          End Test?
                        </Typo>
                      </Grid>

                      <Grid item md={12} xs={12} sm={12}>
                        <Typo
                          className={classes.popupText}
                          variant="body2"
                          style={{ margin: 20, fontSize: 16 }}
                        >
                          If not Press Continue
                        </Typo>
                      </Grid>
                    </div>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      className={classes.dialogButton}
                      id="popupAlign"
                    >
                      <Root>
                        <DialogActions className={classes.actionsButton}>
                          <ExitBut>
                            <OutlinedButton
                              id="buttonALign"
                              width={!mediaQuery ? "140px" : "200px"}
                              onClick={() => endExam()}
                            >
                              <b> QUIT</b>
                            </OutlinedButton>
                          </ExitBut>
                          &nbsp; &nbsp;
                          <ContinueBut>
                            <PrimaryButton
                              id="buttonALign_2"
                              width={!mediaQuery ? "140px" : "200px"}
                              onClick={() => close()}
                            >
                              <b>CONTINUE</b>
                            </PrimaryButton>
                          </ContinueBut>
                          {/* </Root> */}
                          {/* </Grid> */}
                        </DialogActions>
                      </Root>
                    </Grid>
                  </Grid>
                </Container>
              </Card>
            </Grid>
          </div>
        </div>
      </Grid>
    </>
  );
};
