import React from "react";
import { CustomPrimaryButton } from "./CustomButtonStyle";

function PrimaryButton({ children, width, disabled, ...rest }) {
  return (
    <CustomPrimaryButton disabled={disabled} minWidth={width} {...rest}>
      {children}
    </CustomPrimaryButton>
  );
}

export default PrimaryButton;
