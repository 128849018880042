import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
 
  proct: {
    backgroundColor: "brown",
    width:"20%",
    height: "20%",
    position:"fixed",
    top: "26rem",
    
  },

 
}));
