import React from "react";

import { ContentWrapper, Wrapper } from "../../assets/styles/CommonStyles";
import { Container, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import OutlinedButton from "../primary-button/OutlinedButton";
import PrimaryButton from "../primary-button/PrimaryButton";
import Typo from "../typo/Typo";
import { useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "../../redux/Store";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Detection } from "./Detection";
import Webcam from "react-webcam";
// import { useState } from "react";
import Box from "@mui/material/Box";
// import { useEffect } from "react";
import { useEffect, useRef, useState } from "react";
import { getTestQuestionSetById } from "../../redux/slices/Test";
import { IconSymbol } from "../iconsJS/IconSymbol";
import CustomizedSnackbars from "../snack/snack";

import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";

import useSnack from "../../hooks/useSnack";
import { useStyles } from "./Styles";
import "./TestProct.css";

import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    paddingRight: 10,
  },
}));
const Root1 = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    color: "white",
  },
}));

export const TestProct = () => {
  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame, width, height }) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });

  const blazeface = require("@tensorflow-models/blazeface");
  const [faces, setFaces] = useState([]);
  const imgRef = useRef();
  const theme = useTheme();
  const [image, setImage] = useState("");
  // const webcamRef = React.useRef(null);

  const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    console.log(image);
  };

  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ mediaQuery });
  const WebcamComponent = () => <Webcam />;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { questionSetDetails } = useSelector((state) => state.test);
  const [pagestate, setPagestate] = useState(false);
  const [status, setStatus] = useState("");
  const [count, setCount] = useState(null);
  const { open, setOpen, handleClose, message, setMessage, color, setColor } =
    useSnack();

  const { name, description } = questionSetDetails;

  useEffect(() => {
    dispatch(getTestQuestionSetById());
  }, []);

  const nxtInstruction = () => {
    if (count === 1) {
      navigate("/TestInstruction", { state: { isPage: pagestate } });
    } else {
      //  alert("pls take picture");
      setOpen(true);
      setMessage("Please take picture");
      setColor("error");
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <div className={classes.instructionShadow}>
          <Container maxWidth="xl">
            {/* <Grid container style={{ backgroundColor: "green" }}> */}
            <div className={classes.testHead}>
              <Grid item md={12}>
                <Typo
                  variant="h4"
                  color="#354354"
                  fontWeight={600}
                  className={classes.testHeaderProcter}
                >
                  {name}jhm
                </Typo>
              </Grid>
              <br></br>
              <Grid item md={12}>
                {/* {description.map((el) => ( */}
                <Typo color=" #5B656F" variant="body1">
                  {description}ujhk
                </Typo>
              </Grid>
              <br></br>
              <br></br>
              <Grid item md={12} xs={12}>
                <Typo variant="h5" color="#354354" fontWeight={600}>
                  Test Pre-requisites
                </Typo>
              </Grid>
              <br></br>
              <Grid item md={12}>
                <Typo color=" #5B656F" variant="body1">
                  Turn your camera and video on
                </Typo>
                <Typo color=" #5B656F" variant="body1">
                  Be in the visible area of the camera throughout the test
                </Typo>
                <Typo color=" #5B656F" variant="body1">
                  Click your picture and submit for starting the test.
                </Typo>
                <Typo color=" #5B656F" variant="body1">
                  You can Cancel and Retry, If you wish to take another picture.
                </Typo>
                <Typo color=" #5B656F" variant="body1">
                  Once done taking the picture, please click on
                  <b> Get Started</b>, your photo will be automatically be saved
                  and you can get started with the test
                </Typo>

                {/* ))} */}
              </Grid>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                {image != "" ? (
                  <>
                    <br></br>
                    <OutlinedButton
                      onClick={(e) => {
                        e.preventDefault();
                        setImage("");
                        window.location.reload(false);
                      }}
                      className="webcam-btn"
                    >
                      <b> Cancel & Retry</b>
                    </OutlinedButton>
                    <br></br>{" "}
                  </>
                ) : (
                  <OutlinedButton
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                      setCount(1);
                    }}
                    className="webcam-btn"
                  >
                    <b>Take Picture</b>
                  </OutlinedButton>
                )}
              </div>
              {facesDetected !== 1 ? (
                <Stack id="proctscreen" spacing={2}>
                  <div>
                    <Alert severity="error" className="alertMsg">
                      Your Face is not detected properly!
                    </Alert>
                  </div>
                </Stack>
              ) : (
                ""
              )}

              <Grid
                item
                md={12}
                xs={12}
                className={classes.videoBoxTestProct}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  > */}
                <div
                  id="mobdiv"
                  className={classes.mobDivAlign}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "33%",
                    height: "80%",
                    borderRadius: "10px",
                  }}
                >
                  {image == "" ? (
                    <Webcam
                      className={classes.webAlign}
                      ref={webcamRef}
                      forceScreenshotSourceSize
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        // marginBottom: "35px",
                      }}
                    />
                  ) : (
                    <>
                      <img
                        className={classes.webAlign}
                        src={image}
                        // height="80%"
                        // width="70%"
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          height: "100%",
                          bottom: "2rem",
                          // marginBottom: "35px",
                        }}
                      />
                      <br></br>
                    </>
                  )}
                  {/* </div> */}
                </div>

                <Box className={classes.staticButtonContainer}>
                  <Box className={"static__button_wrapper"}>
                    <Box className={classes.buttonWrapper}>
                      
                      <div
                        // id="StaticBtn"
                        className={classes.StaticBtn}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          // marginRight: "2rem",
                          // marginBottom: "-3rem",
                          marginBottom: "0px",
                          marginRight: "0px",
                        }}
                      >
                        <PrimaryButton
                          fullWidth={!mediaQuery ? true : false}
                          width={!mediaQuery ? "100%" : "220px"}
                          onClick={nxtInstruction}
                          // () =>
                          // navigate("/TestInstruction", { state: { isPage: pagestate } })
                          // }
                        >
                          <b
                            className={classes.getStartButtonTestProct}
                            // style={{ position: "relative", left: "20px" }}
                          >
                            GET STARTED
                          </b>
                          <IconSymbol />
                        </PrimaryButton>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </div>
            {/* </Grid> */}
          </Container>
        </div>
        <CustomizedSnackbars
                        open={open}
                        handleClose={handleClose}
                        text={message}
                        type={color}
                      />
      </ContentWrapper>
    </Wrapper>
  );
};
