import { createSlice } from "@reduxjs/toolkit";
import Axios from "../../api/Interceptor";
import { studentId, testQuestionSetId } from "../../utils/Constants";
import { TEST_URL } from "../../api/Url";
import { dispatch } from "../Store";

const initialState = {
  isLoading: false,
  error: null,
  questionSetDetails: {
    name: "",
    description: [],
    durationInMins: "",
    instructionPoints: [],
    noOfQuestions: "",
    noOfSections: "",
    testInstruction: "",
  },
  sectionDetails: null,
  questionDetails: null,
  scoreData: null,
  videoData: null,
  examBoard: null,
  formData: null,
  statusForm: null,
  statusProct: null,
  deleteFile: null,
  checkTpr: null,
  getTpr: null,

  
};

const slice = createSlice({
  name: "test",
  initialState,
  reducers: {
    // Start loading
    startLoading(state) {
      state.isLoading = true;
    },

    insertStudentInformation(state, action) {
      state.studentInformation = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTestQuestionSetSuccess(state, action) {
      state.questionSetDetails = action.payload;
    },

    startTestSuccess(state, action) {
      state.questionDetails = action.payload;
    },
    submitAnswer(state, action) {
      state.questionDetails = action.payload;
    },
    getSections(state, action) {
      state.sectionDetails = action.payload;
    },
    getScoreDataSuccess(state, action) {
      state.scoreData = action.payload;
    },
    videoUpload(state, action) {
      state.videoData = action.payload;
    },

    examBoard(state, action) {
      state.examBoard = action.payload;
    },
    getForm(state, action) {
      state.formData = action.payload;
    },
    getStatuss(state, action) {
      state.statusForm = action.payload;
    },
    getProctoringStatus(state, action) {
      state.statusProct = action.payload;
    },
    getDeleteFile(state, action) {
      state.deleteFile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {

// } = slice.actions;

export function getTestQuestionSetById() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(
        TEST_URL.getTestInstructionById(testQuestionSetId)
      );
      dispatch(slice.actions.getTestQuestionSetSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startTest(sectionPage, callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post(
        TEST_URL.startTest(studentId, testQuestionSetId),
        { isSectionPage: sectionPage }
      );
      dispatch(slice.actions.startTestSuccess(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      callback(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };
}

export function answerTest(obj, callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(
        TEST_URL.submitAnswer(studentId, testQuestionSetId),
        obj
      );
      dispatch(slice.actions.submitAnswer(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function putStudentInformation(obj,callback){
//   return async() =>{

//     try{
//       const response = await Axios.put(
//         TEST_URL.putInformation(studentId),
//         obj
//       );
//       dispatch(slice.actions.insertStudentInformation(response.data.data));
//       callback(response.data)
//     }
//     catch(error){
//       dispatch(slice.actions.hasError(error))
//     }
//   }
// }
export function checkingTpr(callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(
        TEST_URL.tprCheck(studentId, testQuestionSetId)
      );
      // dispatch(slice.actions.tprCheck(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function gettingTpr(callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(
        TEST_URL.tprGet(studentId, testQuestionSetId)
      );
      // dispatch(slice.actions.tprGet(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getSection(callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(
        TEST_URL.getSections(studentId, testQuestionSetId)
      );
      dispatch(slice.actions.getSections(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getFormSection = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.getFormData(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};

export function getScoreData(testExeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(
        TEST_URL.getScoreData(studentId, testExeId, testQuestionSetId)
      );
      dispatch(slice.actions.getScoreDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addSection(testSectionId, callback) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post(
        TEST_URL.addSections(studentId, testQuestionSetId, testSectionId)
      );

      callback(response.data);
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export const uploadVideo = (payload,onUploadProgress,callback) => async () => {
//   var defaultMessage = 'Uh-Oh something went wrong Please try again';

//   try {
//     const { data } = await Axios.post(TEST_URL.videoUpload(studentId, testQuestionSetId),payload, {onUploadProgress: onUploadProgress});
// }
export const examBoard = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.examBoard(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};
export const getDegree = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.getDegree(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};

export const getCollege = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.getCollege(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};

export const getFormStatus = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.formStatus(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};
export const getProctStatus = (testQuestionSetId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.proctoring(testQuestionSetId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};

export const getDepartment = (studentId, callback) => async () => {
  var defaultMessage = "Expectation Failed";

  try {
    const { data } = await Axios.get(TEST_URL.getDepartment(studentId));
    callback(data);
  } catch (error) {
    console.log(error);
    callback({
      success: false,
      message: error.response?.data?.message || defaultMessage,
      ...error,
    });
  }
};

export const putStudentEductionInformation =
  (studentId, dataValue, callback) => async () => {
    var defaultMessage = "Expectation Failed";
    try {
      const { data } = await Axios.put(
        TEST_URL.putInformation(studentId),
        dataValue
      );
      callback(data);
    } catch (error) {
      console.log(error);
      callback({
        success: false,
        message: error.response?.data?.message || defaultMessage,
        ...error,
      });
    }
  };

export const uploadVideo =
  (payload, onUploadProgress, callback) => async () => {
    var defaultMessage = "Uh-Oh something went wrong Please try again";

    try {
      const { data } = await Axios.post(
        TEST_URL.videoUpload(studentId, testQuestionSetId),
        payload,
        { onUploadProgress: onUploadProgress }
      );
      callback(data);
    } catch (error) {
      console.log(error);
      callback({
        success: false,
        message: error.response?.data?.message || defaultMessage,
        ...error,
      });
    }
  };
export function FileDeleteOpt(obj, callback, id) {
  return async () => {
    console.log(obj);
    console.log(id);

    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(
        TEST_URL.fileDelete(testQuestionSetId, studentId, obj),
        obj
      );
      // dispatch(slice.actions.fileDelete(response.data.data));
      callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
