import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "../../redux/Store";
import { BackArrow } from "../iconsJS/BackArrow";
import Typo from "../typo/Typo";
import { useStyle } from "./Style";

export const TestHeader = (props) => {
  const classes = useStyle();
  const { scoreData } = useSelector((state) => state.test);

  return (
    <div className={classes.testBar}>
      <Grid container spacing={0}>
        {/* <Grid xs={1} justifyContent={"center"} container alignItems={"center"}>
          {!props.noBack && <BackArrow />}
        </Grid> */}
        <Grid item xs={12} md={10} container alignItems={"center"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typo
              className={classes.testHeaderResultPage}
              fontWeight={"bold"}
              variant="h6"
            >{`${scoreData?.title || ""} Test Results`}</Typo>
            {/* <Typo variant={"subtitle1"}>Section 1: Chemistry</Typo> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={2} container alignItems={"center"}>
          {`Result Date- ${scoreData?.reportedDateStr || ""}`}
        </Grid>
      </Grid>
    </div>
  );
};
