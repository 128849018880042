import React from "react";
import Loader from "react-js-loader";
import { useMediaQuery, useTheme } from "@material-ui/core";
import "../../components/common-components/Loader.css";
export const Loaders = () => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        bottom: "150px",
        paddingTop: mediaQuery ? "20%" : "82%",
      }}
      className="LoaderAlign"
    >
      <Loader
        type="box-rectangular"
        bgColor={"#0000FF"}
        color={"#0000FF"}
        size={100}
      />
    </div>
  );
};
