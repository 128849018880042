import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  testBar: {
    display: "flex",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, #DDE9FF -52.7%, rgba(221, 233, 255, 0) 100%)",
    padding: 45,
  },
  calendar: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    paddingRight: 80,
    paddingRight: 100,
  },
  "@media (max-width: 600px)": {
    testHeaderResultPage: {
      marginTop: "45px !important",
    },
  },
}));
