import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  confirmBlock: {
    display: "flex",
    width: "100%",
    height: "100%",
    // padding:"10%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10%",
  },
  confirmContainer: {
    display: "flex",
    justifyContent: "center",
  },
  confirmDescription: {
    display: "flex",
    justifyContent: "center",
    // alignItems:"center",
  },
  confirmContent: {
    display: "flex",
    justifyContent: "center",
  },
}));