import React from "react";
import ReviewsProvider from "./ReviewsProvider";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { styled } from "styled-components";

const ReviewsBar = (props) => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const { score } = props;

  // function for calculating the color
  const calcColor = (percent, start, end) => {
    let a = percent / 100,
      b = (end - start) * a,
      c = b + start;

    // return an CSS hsl color string
    return "hsl(" + c + ", 100%, 50%)";
  };

  return (
    <ReviewsProvider valueStart={0} valueEnd={score}>
      {(value) => (
        <SemiCircleProgressBar
          style={{ position: "relative", left: "30px" }}
          percentage={score}
          showPercentValue
          strokeWidth={28}
          diameter={mediaQuery ? 230 : 140}
          stroke={score < 50 ? "#FF7284" : "#00CA99"}
        />
      )}
    </ReviewsProvider>
  );
};

export default ReviewsBar;
