import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { useStyle } from "./Style";
import Typo from "../typo/Typo";
import "../../components/ProgressBar/ProgressBar.css";
// const styles = props => ({
//   colorPrimary: {
//     backgroundColor: '#00695C',
//   },
//   barColorPrimary: {
//     backgroundColor: '#B2DFDB',
//   }
// });

export default function LinearDeterminate({ value }) {
  //   const [progress, setProgress] = React.useState(0);

  //   React.useEffect(() => {
  //     const timer = setInterval(() => {
  //       setProgress((oldProgress) => {
  //         if (oldProgress === 100) {
  //           return 0;
  //         }
  //         const diff = Math.random() * 10;
  //         return Math.min(oldProgress + diff, 100);
  //       });
  //     }, 500);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []);
  const classes = useStyle();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{ width: "65%" }}
        style={{ alignItems: "center", padding: "2rem" }}
        className="LinearLineBar"
      >
        <LinearProgress
          className={classes.ProgressLine}
          id="ProgressLine"
          variant="determinate"
          value={value}
          style={{ height: "1rem", borderRadius: "1rem" }}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typo
          style={{ color: "#2D6CDF", fontWeight: "500" }}
          className="percentageValue"
        >
          {value || 0}%
        </Typo>
      </Box>
    </Box>
  );
}
