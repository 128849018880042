import { TextField } from "@mui/material";
import { useStyles } from "./Styles";

function InputField({
  id,
  label,
  variant,
  value,
  type,
  onChange,
  helperText,
  error,
  placeholder,
  className,
  hasNoBorder,
  InputProps,
  disabled,
  onKeyPress,
  inputProps,
  ...props
}) {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      label={label}
      type={type}
      variant={variant}
      value={value || ""}
      onChange={onChange}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      InputProps={{
        ...InputProps,
        classes: { notchedOutline: hasNoBorder && classes.noBorder },
      }}
      onKeyPress={onKeyPress}
      inputProps={inputProps}
      fullWidth
      {...props}
    />
  );
}

export default InputField;
