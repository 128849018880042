import React from "react";
import { CustomOutlinedButton } from "./CustomButtonStyle";

function OutlinedButton({ children, width, ...rest }) {
  return (
    <CustomOutlinedButton minWidth={width} variant={"outlined"} {...rest}>
      {children}
    </CustomOutlinedButton>
  );
}

export default OutlinedButton;
