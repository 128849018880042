import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  ChemistryTestFullPart: {
    height: "50%",
    flexDirection: "column",
    paddingLeft: "5%",
    background:
      "linear-gradient(180deg, #DDE9FF -52.7%, rgba(221, 233, 255, 0) 50%)",
  },
  sectionShadow: {
    background:
      "linear-gradient(180deg, #DDE9FF -52.7%, rgba(221, 233, 255, 0) 30%)",
  },
  testStart: {
    fontWeight: 600,
    color: "#354354",
  },
  ScienceTestStyle: {
    padding: 15,
    // paddingLeft: 20,
    fontWeight: 600,
    fontSize: "32px",
    color: "#354354",
    // backgroundColor:"red",
  },
  CardStyle: {
    width: "100%",
    alignSelf: "center",
    padding: "3%",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)!important",
    borderRadius: " 12px",
  },
  CardContainer: {
    // paddingLeft: 2,
    paddingTop: 20,
  },
  tableHeadStyles: {
    backgroundColor: "#EDEAFE",
    // backgroundColor:"red",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)!important",
    borderRadius: "23px",
    width: 100,
  },
  table: {
    // display:"flex",
    // justifyContent:"space-between",
    borderRadius: "8px",
    marginTop: 15,
    // paddingTop: "10px",
    paddingRight: "0px",
    // backgroundColor: " #EDEAFE",
    // paddingLeft: "0px",
    // backgroundColor:"red",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)!important",
    borderBottom: "none",
  },
  // header: {
  //   backgroundColor: " #EDEAFE",
  // },
  sectionBan: {
    display: "flex",
  },
  sectionTable: {
    borderRadius: "8px",
    paddingTop: "0px",
    paddingRight: "0px",
    // backgroundColor:"red",
    // paddingLeft:"0px",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2)!important",
    borderBottom: "none",
  },
  sectionInstruct: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  sectionButton: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  testInstruct: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  ellipseIcon: {
    alignItems: "center",
  },
  Instruction: {
    // paddingLeft: "2%",
  },
  timing: {
    padding: 6,
    marginLeft: 10,
    fontWeight: 600,
  },
  divider: {
    padding: 12,
    color: "#C4C4C4",
  },
  minutes: {
    padding: 6,
    marginLeft: 10,
    fontWeight: 600,
  },
  tableCell: {
    borderBottom: "none",
    paddingLeft: 50,
    paddingRight: 60,
  },
  testHead: {
    padding: 30,
    // paddingTop:2,
    paddingLeft: 3,
    justifyContent: "center",
    alignItems: "center",
    color: "#354354",
    fontWeight: 500,
  },
  testHeadStyles: {
    color: "#354354",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },
  sectionstyle: {
    display: "flex",
    padding: 0,
    backgroundColor: "#EDEAFE",
    // justifyContent: "right",
    // gap: 30,
    justifyContent: "space-around",
    padding: "15px",
    // borderRadius: "6px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  testStart: {
    fontWeight: "700",
    marginLeft: "75px",
  },
  Sectiongap: {
    width: "40%",
  },
  "@media (max-width: 600px)": {
    testNumberAlign: {
      fontSize: "24px !important",
      fontWeight: "bold !important",
      color: "#354354 !important",
    },
    teststyle: {
      position: "relative",
      left: "30px",
    },
    getStartBtn: {
      position: "relative",
      left: "25px !important",
    },
    testHeaderSection: {
      marginTop: "60px !important",
    },
    sectionstyle: {
      display: "flex",
      padding: 0,
      backgroundColor: "#EDEAFE",
      // justifyContent: "right",
      // gap: 30,
      justifyContent: "space-around",
      padding: "10px",
      // borderRadius: "6px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },
  buttonWrapper: ({ mediaQuery }) => ({
    textAlign: "center",
    padding: "0 12px ",
    // backgroundColor:"white",
    paddingBottom: mediaQuery ? 80 : 22,
  }),
  buttonWrapper1: {
    textAlign: "center",
    padding: "0 12px 55px 240px ",
    backgroundColor: "white",
    paddingBottom: 22,
  },
  staticButtonContainer: ({ mediaQuery }) => ({
    position: "relative",
    display: "block",
    minHeight: `${50 + (mediaQuery ? 80 : 22)}px`,
    "& .static__button_wrapper": {
      position: "fixed",
      left: "15px",
      right: "15px",
      bottom: 0,
    },
  }),
}));
