import { useMediaQuery, useTheme } from "@material-ui/core";
import { Dialog, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { answerTest, startTest } from "../../redux/slices/Test";
import { useDispatch, useSelector } from "../../redux/Store";
import Bundle from "../bundle-type/Bundle";
import { CustomFooter } from "../footer/CustomFooter";
import { getProctStatus } from "../../redux/slices/Test";
import { Calendar } from "../iconsJS/Calendar";
import Video from "../VideoAudioRecording/Video";
import MultiChoice from "../multi-choice/MultiChoice";
import { PopupCard } from "../popup/PopupCard";
import { QuestionComponent } from "../question/Question";
import { AnswerRadioType } from "../single-choice/AnswerRadioType";
import CustomizedSnackbars from "../snack/snack";
import { useStyle } from "../test-header/Style";
import TimerCompo from "../test-timer/TimerCompo";
import Typo from "../typo/Typo";
import { useStyles } from "./Style";
import { AnswerFile } from "../file-upload/FileUpload";
import { ContentWrapper } from "../../assets/styles/CommonStyles";
import WebcamDemo from "../Proctoring/WebcamDemo";
import { testQuestionSetId } from "../../utils/Constants";
import "../../components/question-ans/Rendering.css";
// import { Rendering } from './Rendering';
const Sec = styled("div")(({ theme }) => ({}));
const Answer = styled("div")(({ theme }) => ({
  paddingRight: theme.spacing(3.75),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    marginLeft: "2rem",
  },
}));

export const Rendering = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const classHeader = useStyle();
  const dispatch = useDispatch();
  const [filePath, setFilePath] = useState(null);
  const [openP, setOpenp] = React.useState(props.open);
  // const [pagestate, setPagestate] = useState(true);
  const [proct, setProct] = useState(false);
  // const handleClickOpen = () => {
  // setOpenp(true);
  // };
  const handleClose = () => {
    setOpenp(false);
  };
  useEffect(() => {
    setOpenp(props.openP);
  }, []);
  useEffect(() => {
    dispatch(
      getProctStatus(testQuestionSetId, (res) => {
        if (res.success) {
          setProct(true);
        } else {
          setProct(false);
        }
      })
    );
  }, []);
  function exDialog() {
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PopupCard close={handleClose} endExam={props.endExam} />
      </Dialog>
    </>;
  }

  const [open, setOpen] = React.useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [selectedChoice, setSelectedChoices] = useState([]);
  const { questionDetails } = useSelector((state) => state.test);
  const [time, setTime] = useState(null);
  // const { ex, setEx, handleClosee, message, setMessage, color, setColor } =
  // useSnack();
  // const { message, setMessage } = useSnack();
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshTime, setRefreshTime] = useState(0);
  const [stopVideoRecording, setStopVideoRecording] = useState(false);
  const [videoUploadSuccess, setVideoUploadSuccess] = useState({});
  const VideoRecordingResponse = (data) => {
    setVideoUploadSuccess(data);
  };
  const VideoRecordingStop = (data) => {
    setStopVideoRecording(data);
  };
  console.log(stopVideoRecording);
  const RecordingResponse = (data) => {
    setVideoUploadSuccess(data);
  };
  useEffect(() => {
    window.history.forward();
    if (questionDetails) {
      setQuestionData({ ...questionDetails });
      setTime(questionDetails.expectedTime);
    } else {
      dispatch(
        startTest(false, (response) => {
          console.log(response.data, "dddd");
          if (response.message === "Test attempt exceeded") {
            // setEx(true);
            // setMessage(response.message);
            // setColor("error");
            navigate(
              `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
            );
          } else if (response.message === "Test completed") {
            setOpen(true);
          } else {
            setQuestionData({ ...response.data });
            setTime(response.data.expectedTime);
          }
        })
      );
    }
  }, []);

  const setChoice = (e) => {
    setSelectedChoices([e.target.value]);
  };

  const setMultiChoice = (e) => {
    const { value, checked } = e.target;
    let arr = selectedChoice;
    if (checked) {
      arr.push(value);
    } else {
      arr = arr.filter((item) => item !== value);
    }
    setSelectedChoices(arr);
  };

  const nextButton = () => {
    const obj = {
      testExecutionId: questionData?.testExecutionId,
      questionId: questionData?.id,
      choices:
        questionData?.type === "BUNDLE"
          ? selectedChoice.map((item) => item.id)
          : selectedChoice,
      leadSquare: false,
      time: time,
    };

    if (questionData?.type === "FILE_UPLOAD") {
      if (filePath) {
        delete obj.choices;
        let payload = { ...obj, uploadedFile: filePath };
        setTime(null);
        dispatch(
          answerTest(payload, (response) => {
            console.log(response.data.id);
            if (response.message === "Next TestSection") {
              navigate(`/TestInstruction`);
            } else if (response.message === "Test completed") {
              navigate(
                `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
              );
            } else {
              setQuestionData({ ...response.data });
              setTime(response.data.expectedTime);
              setRefreshTime(!refreshTime);
              setFilePath(null);
            }
          })
        );
      } else {
        setOpen(true);
      }
    } else if (selectedChoice.length > 0) {
      if (questionData?.type === "SINGLE_SELECT") {
        console.log("working...");
        setTime(null);
        dispatch(
          answerTest(obj, (response) => {
            if (response.message === "Next TestSection") {
              navigate(`/TestInstruction`);
            } else if (response.message === "Test completed") {
              navigate(
                `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
              );
            } else {
              setQuestionData({ ...response.data });
              setTime(response.data.expectedTime);
              setRefreshTime(!refreshTime);
            }
            setSelectedChoices([]);
          })
        );
      } else if (
        questionData?.type === "MULTI_CHOICE" &&
        selectedChoice.length > 0
      ) {
        setTime(null);
        dispatch(
          answerTest(obj, (response) => {
            if (response.message === "Next TestSection") {
              navigate(`/TestInstruction`);
            } else if (response.message === "Test completed") {
              navigate(
                `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
              );
            } else {
              setQuestionData({ ...response.data });
              setTime(response.data.expectedTime);
              setRefreshTime(!refreshTime);
            }
            setSelectedChoices([]);
          })
        );
      } else if (questionData?.type === "BUNDLE" && selectedChoice.length > 0) {
        setTime(null);
        dispatch(
          answerTest(obj, (response) => {
            if (response.message === "Next TestSection") {
              navigate(`/TestInstruction`);
            } else if (response.message === "Test completed") {
              navigate(
                `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
              );
            } else {
              setQuestionData({ ...response.data });
              setTime(response.data.expectedTime);
              setRefreshTime(!refreshTime);
            }
            setSelectedChoices([]);
          })
        );
      } else {
        dispatch(
          answerTest(obj, (response) => {
            setTime(null);
            if (response.message === "Answer Time Not Provide") {
              exDialog();
            } else {
              setQuestionData({ ...response.data });
              setTime(response.data.expectedTime);
            }
            setSelectedChoices([]);
          })
        );
      }
    } else {
      if (questionData?.type === "VIDEO") {
        if (filePath) {
          delete obj.choices;
          let payload = { ...obj, videoChoice: filePath };
          setTime(null);
          dispatch(
            answerTest(payload, (response) => {
              if (response.message === "Next TestSection") {
                navigate(`/TestInstruction`);
              } else if (response.message === "Test completed") {
                navigate(
                  `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
                );
              } else {
                setQuestionData({ ...response.data });
                setTime(response.data.expectedTime);
                setRefreshTime(!refreshTime);
                setFilePath(null);
                setStopVideoRecording(false);
                // window.location.reload();
              }
            })
          );
        } else {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
  };

  /**
   * This method is called when the question time gets over
   */
  const over = "00:00:00";
  const questionTimeOver = () => {
    const obj = {
      testExecutionId: questionData?.testExecutionId,
      questionId: questionData?.id,
      choices: [],

      time: time,
    };
    dispatch(
      answerTest(obj, (response) => {
        if (response.message === "Next TestSection") {
          navigate(`/TestInstruction`);
        } else if (response.message === "Test completed") {
          navigate(
            `/Confirmation/${questionData?.testTitle}/${questionData?.testExecutionId}`
          );
        } else {
          setQuestionData({ ...response.data });
          setTime(response.data.expectedTime);
          setRefreshTime(!refreshTime);
        }
      })
    );
  };

  const onBundleChange = (item, choice) => {
    var arr = [];
    if (selectedChoice.some((exist) => exist.bundleNo === item.bundleNo)) {
      if (
        selectedChoice.some(
          (exist) => exist.bundleNo === item.bundleNo && exist.id === choice.id
        )
      ) {
        arr = selectedChoice.filter(
          (exist) => exist.bundleNo !== item.bundleNo && exist.id !== choice.id
        );
      } else {
        arr = selectedChoice
          .filter((exist) => exist.bundleNo !== item.bundleNo)
          .concat({ ...item, ...choice });
      }
    } else {
      arr = selectedChoice.concat({ ...item, ...choice });
    }
    setSelectedChoices(arr);
  };

  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const endExam = () => {
    const obj = {
      testExecutionId: questionData?.testExecutionId,
      questionId: questionData?.id,
      choices: [],
      leadSquare: false,
      time: time,
      isEndTest: true,
    };
    dispatch(
      answerTest(obj, (response) => {
        if (response.message === "Test ended") {
          navigate(`/Result/${questionData?.testExecutionId}`);
        }
      })
    );
  };

  useEffect(() => {
    if (time === 0) {
      questionTimeOver();
    }
  }, [time]);

  return (
    <Sec>
      <div className={classes.QuestAns}>
        <ContentWrapper>
          <Grid container>
            <Grid item md={12}>
              <div className={classHeader.testBar}>
                <Grid container className={classHeader.arrowIcon}>
                  <Grid item md={6} xs={12}>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className={classes.testHeaderItemAlign}
                    >
                      {" "}
                      <Typo variant="body1" color="#5B656F">
                        {questionData?.testTitle} /
                      </Typo>
                      <Typo>Section {questionData?.currentTestSection}</Typo>
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <Typo variant="h4" fontWeight="600" color="#354354">
                        Section {questionData?.currentTestSection}:{" "}
                        {questionData?.testSectionName}
                      </Typo>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    justifyContent={!mediaQuery ? "flex-start" : "flex-end"}
                    style={{ display: "flex", marginTop: 10 }}
                    alignItems={"center"}
                    // className={classHeader.calendar}
                  >
                    <Calendar
                      style={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    />
                    <div
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typo
                        variant={mediaQuery ? "h6" : "h7"}
                        id="reaonings"
                        color="#354354"
                        fontWeight="400"
                      >
                        Time Remaining -
                      </Typo>
                      <div
                        style={{ marginLeft: 10, fontWeight: "bold" }}
                        className={"helo"}
                      >
                        {" "}
                        {time ? (
                          <TimerCompo
                            className="name"
                            time={time}
                            mediaQuery={mediaQuery}
                            refresh={refreshTime}
                            getRemainingTime={(times) => setTime(times)}
                            stopVideoRecording={stopVideoRecording}
                          />
                        ) : (
                          <b>{over} </b>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* <TestHeader time={questionData?.remainingTime} /> */}
            </Grid>
            <Grid container className="CardAlignItems">
              {/* <Container maxWidth="xl" style={{display:"flex"}}> */}
              <Grid item md={6} xs={12} className={classes.questStyle}>
                <QuestionComponent
                  question={questionData?.question}
                  currentQuestion={questionData?.currentQuestionNo}
                  imageUrl={questionData?.imgURL}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                {questionData?.type === "SINGLE_SELECT" && (
                  <div>
                    <Typo
                      variant="body1"
                      fontWeight={600}
                      padding={4}
                      color={"#5B656F"}
                      className={classes.questionTextStyle}
                      // alignItems="left"
                    >
                      Please choose one of the following answer
                    </Typo>

                    <Answer
                      id="scrollable"
                      style={{
                        position: "relative",
                        height: "370px",
                        // overflowY: "scroll",
                        marginBottom: "2%",
                        textAlign: "justify",
                      }}
                    >
                      <AnswerRadioType
                        choice={questionData?.choices}
                        selectedChoice={selectedChoice}
                        handleChange={setChoice}
                      />
                    </Answer>
                  </div>
                )}
                {questionData?.type === "MULTI_CHOICE" && (
                  <div>
                    <Typo
                      variant="body1"
                      fontWeight={600}
                      padding={4}
                      color={"#5B656F"}
                      className={classes.questionTextStyle}
                    >
                      Please choose correct answers from below:
                    </Typo>
                    <Answer
                      id="scrollable"
                      style={{
                        position: "relative",
                        height: "370px",
                        overflowY: "scroll",
                        marginBottom: "2%",
                        textAlign: "justify",
                      }}
                    >
                      <MultiChoice
                        choice={questionData?.choices}
                        selectedChoice={selectedChoice}
                        handleChange={setMultiChoice}
                      />
                    </Answer>
                  </div>
                )}
                {questionData?.type === "BUNDLE" && (
                  <div>
                    <Typo
                      variant="body1"
                      fontWeight={600}
                      padding={4}
                      color={"#5B656F"}
                      className={classes.questionTextStyle}
                    >
                      Please choose correct answers from below:
                    </Typo>
                    <Answer
                      id="scrollable"
                      style={{
                        position: "relative",
                        height: "370px",
                        overflowY: "scroll",
                        marginBottom: "2%",
                        textAlign: "justify",
                      }}
                    >
                      <Bundle
                        choice={questionData?.choices}
                        selectedChoice={selectedChoice}
                        onChange={onBundleChange}
                        totalBundle={questionData?.totalBundle}
                      />
                    </Answer>
                  </div>
                )}

                {questionData?.type === "VIDEO" && (
                  <div>
                    <Typo
                      variant="body1"
                      fontWeight={600}
                      padding={4}
                      color={"#5B656F"}
                      className={classes.questionTextStyle}
                    >
                      Record video
                    </Typo>
                    <Answer>
                      <Video
                        setFilePath={(path) => setFilePath(path)}
                        filePath={filePath}
                        VideoRecordingResponse={VideoRecordingResponse}
                        VideoRecordingStop={VideoRecordingStop}
                      />
                    </Answer>
                  </div>
                )}
                {questionData?.type === "FILE_UPLOAD" && (
                  <div>
                    <Typo
                      variant="body1"
                      fontWeight={600}
                      padding={4}
                      color={"#5B656F"}
                      className={classes.questionTextStyle}
                    >
                      Please upload your file below
                    </Typo>
                    <Answer>
                      <AnswerFile
                        setFilePath={(path) => setFilePath(path)}
                        filePath={filePath}
                        RecordingResponse={RecordingResponse}
                      />
                    </Answer>
                  </div>
                )}
              </Grid>
              {questionData?.type !== "VIDEO" && proct === true ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <WebcamDemo />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </ContentWrapper>
        <CustomFooter
          endExam={endExam}
          open={openDialog}
          nextProp={() => nextButton()}
          totalQuestion={questionData?.totalNoOfQuestionSection}
          currentQuestion={questionData?.currentQuestionNo}
          disabled={
            questionData?.type === "VIDEO" &&
            videoUploadSuccess.message === "file uploaded successfully"
              ? false
              : questionData?.type === "VIDEO"
              ? true
              : false
          }
        />

        <CustomizedSnackbars
          // style={{display:"flex",alignSelf:"center",marginLeft:300}}
          open={open}
          handleClose={() => setOpen(false)}
          type={"error"}
          text={
            questionData?.type === "VIDEO"
              ? "Please record video"
              : questionData?.type === "FILE_UPLOAD"
              ? "Please upload file"
              : "Please Select a choice"
          }
        />
        {/* <CustomizedSnackbars
 ex={ex}
 handleClose={handleClosee}
 text={message}
 type={color}
 /> */}
      </div>
    </Sec>
  );
};
