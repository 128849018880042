import { AppBar, Box, Container, Toolbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import Axios from "../../api/Interceptor";
import { BASE_URL_V1, COOKIE_DOMAIN } from "../../api/Url";
import clab from "../../assets/images/careerlabs.jpg";
import { accessToken, removeCookie, studentId } from "../../utils/Constants";
import { SigninPeople } from "../iconsJS/SigninPeople";
import { SigninPreview } from "../iconsJS/SigninPreview";
import { useStyles } from "./Styles";

export default function CustomAppbar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Axios({
      baseURL: BASE_URL_V1,
      url: `/students/${studentId}/logout`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        if (response.data.message === "success") {
          removeCookie("studentId", { path: "", domain: COOKIE_DOMAIN });
          removeCookie("accessToken", { path: "", domain: COOKIE_DOMAIN });
          removeCookie("refreshToken", { path: "", domain: COOKIE_DOMAIN });
          window.close();
        }
      }
    });
    setAnchorEl(null);
    window.top.close();
  };
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{ backgroundColor: "white" }}
          className={classes.headingAlignFixed}
        >
          <Toolbar
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: "4px 4px 18px 1px rgba(146, 170, 212,0.2)",
            }}
          >
            <img src={clab} alt="clab" className={classes.clImg} />

            <div className={classes.headerIcons}>
              <IconButton className={classes.Avatar}>
                <SigninPeople />
              </IconButton>
              {/* <IconButton>
                <Typo>Antony</Typo>
              </IconButton> */}
              <IconButton
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <SigninPreview />
              </IconButton>
            </div>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
