const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL_V1 = process.env.REACT_APP_BASE_API_URL_V1;
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

const TEST_URL = {
  getTestInstructionById: (questionSetId) => `/assessments/testMetaData/${questionSetId}`,
  startTest: (studentId, testQuestionSetId) =>
    `/students/${studentId}/testQuestionSet/${testQuestionSetId}/testExecutions`,
  submitAnswer: (studentId) => `/students/${studentId}/answers/new`,
  getSections: (studentId, testQuestionSetId) =>
    `/students/${studentId}/testQuestionSet/${testQuestionSetId}/testSections`,
  addSections: (studentId, testQuestionSetId, testSectionId) =>
    `/students/${studentId}/testQuestionSet/${testQuestionSetId}/testSection/${testSectionId}`,
  getScoreData: (studentId, testExecutionId, testQuestionSetId) =>
    // `/students/1e1cb115-0806-415f-9259-811kk0324rk5/testExecution/309c0b60-2274-4f2c-a521-e833826aa486/topicTestReport/d52c3f5c-1a5b-41a1-b7bf-95ca3d2453dd`
    `/students/${studentId}/testExecution/${testExecutionId}/topicTestReport/${testQuestionSetId}`,
  // logoutUrl : (studentId) => `/students/${studentId}/logout`,
  // videoUpload :(studentId,testQuestionSetId) =>
  // `/file/TestQuestionSet/${testQuestionSetId}/student/${studentId}`,

  fileDelete: (testQuestionSetId, studentId, id) =>
    `/file/delete/testQuestionSet/${testQuestionSetId}/student/${studentId}?file=${id}`,
  tprCheck: (studentId, testQuestionSetId) =>
    `/clsaTpr/status/student/${studentId}/testQuestionSet/${testQuestionSetId}`,
  tprGet: (studentId, testQuestionSetId) =>
    `/clsaTpr/student/${studentId}/testQuestionSet/${testQuestionSetId}`,

  logoutUrl: (studentId) => `/students/${studentId}/logout`,
  videoUpload: (studentId, testQuestionSetId) =>
    `/file/TestQuestionSet/${testQuestionSetId}/student/${studentId}`,
  examBoard: (studentId) => `${BASE_URL_V1}students/${studentId}/examBoard`,
  getDegree: (studentId) => `${BASE_URL_V1}students/${studentId}/degrees`,
  getCollege: (studentId) => `${BASE_URL_V1}students/${studentId}/colleges/default`,
  getDepartment: (studentId) => `${BASE_URL_V1}students/${studentId}/departments/default`,
  putInformation: (studentId) => `/students/${studentId}/clsa/personalAndEducationalform`,
  getFormData: (studentId) => `${BASE_URL}assessment/students/${studentId}/clsa/form`,

  formStatus: (studentId) => `/clsa/form/status/${studentId}`,
  proctoring: (testQuestionSetId) => `/proctor/testQuestionSet/${testQuestionSetId}  `,
};

export { BASE_URL, TEST_URL, BASE_URL_V1, COOKIE_DOMAIN };
