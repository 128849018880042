import { Box, Container } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getScoreData } from "../../redux/slices/Test";
import { useDispatch, useSelector } from "../../redux/Store";
import PieChart from "../chart/Pie";
import { CorrectAttempt } from "../iconsJS/CorrectAttempt";
import { InCorrectAttempt } from "../iconsJS/InCorrectAttempt";
import { MeanEllipse } from "../iconsJS/MeanEllipse";
import { Vector } from "../iconsJS/Vector";
import OutlinedButton from "../primary-button/OutlinedButton";
import { ResultPart } from "../result-part/ResultPart";
import { TestHeader } from "../test-header/TestHeader";
import Typo from "../typo/Typo";
import { useStyles } from "./Styles";
import "./Result.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ContentWrapper, Wrapper } from "../../assets/styles/CommonStyles";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.up("lg")]: {
    marginLeft: "120px",
  },
}));
const Space = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.only("xs")]: {
    marginRight: "105px",
  },
}));
const Around = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.up("xs")]: {
    paddingLeft: "6px",
  },
}));

const Around1 = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    // paddingTop:"30px",
    paddingBottom: "20px",
  },
}));

const Gap = styled("div")(({ theme, props }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "17px",
    paddingBottom: 20,
  },
  [theme.breakpoints.down("md")]: {
    // paddingTop:"30px",
    marginTop: "40px",
  },
}));

const Gaping = styled("div")(({ theme }) => ({
  // marginTop : "10px",
  textAlign: "center",
  [theme.breakpoints.only("xs")]: {
    marginTop: "30px",
  },
}));

function SimpleCollapse(props) {
  const [checked, setChecked] = React.useState(false);
  const dispatch = useDispatch();
  const { testExecutionId } = useParams();
  const { scoreData } = useSelector((state) => state.test);

  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ mediaQuery });

  const Sections = [
    {
      Section: "Section 1",
      Durations: "40 mins",
    },
    {
      Section: "Section 2",
      Durations: "24 mins",
    },
    {
      Section: "Section 3",
      Durations: "60 mins",
    },
    {
      Section: "Section 4",
      Durations: "20 mins",
    },
  ];
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    dispatch(getScoreData(testExecutionId));

    return () => {};
  }, []);

  const avgTimeAllQues = () => {
    if (scoreData) {
      return scoreData?.insights?.cards?.find(
        (el) => el.title === "Average Time of all Questions"
      ).events;
    } else return null;
  };

  const avgTimeAllSec = () => {
    if (scoreData) {
      return scoreData?.insights?.cards?.find(
        (el) => el.title === "average time for each section"
      ).events;
    } else return null;
  };

  const overAllTimeManaged = () => {
    if (scoreData) {
      return scoreData?.insights?.cards?.find(
        (el) => el.title === "Overall Time"
      ).events;
    } else return null;
  };
  // const sectionPercentage = () => {
  //   if (scoreData) {
  //     return scoreData?.insights?.cards?.find(
  //       (el) => el.title === "Sectionwise Percentage"
  //     ).events;
  //   } else return null;
  // }
  const findIcon = (icon) => {
    if (icon === "mean")
      return <MeanEllipse style={{ alignItems: "center" }} />;
    else if (icon === "Right")
      return <CorrectAttempt style={{ padding: "2px" }} />;
    else if (icon === "Wrong") return <InCorrectAttempt />;
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Grid container>
          <Grid item xs={12}>
            <TestHeader noBack />
          </Grid>
          <Container maxWidth={"xl"}>
            {scoreData && <ResultPart />}
            <Grid container>
              <Grid
                xs={12}
                md={12}
                paddingTop={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {!checked ? (
                  <Space>
                    <OutlinedButton
                      label="View More"
                      onClick={() => setChecked(!checked)}
                    >
                      <b>View more</b>
                    </OutlinedButton>
                  </Space>
                ) : null}
              </Grid>
            </Grid>
          </Container>

          <Collapse
            in={checked}
            orientation={width < 700 ? "horizontal" : "vertical"}
            flexItem
            style={{
              height: width < 700 ? 20 : 300,
              width: "100%",
            }}
            classes={{
              root: classes.collapseWrap,
            }}
          >
            <Container maxWidth={"xl"}>
              <Grid container className={classes.GridContainer}>
                <Grid item md={12} xs={12} style={{ display: "flex" }}>
                  <Grid container style={{ display: "flex" }}>
                    <Grid container>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        paddingLeft={1}
                        style={{
                          borderRadius: "12px",
                          boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
                        }}
                      >
                        <Around1>
                          <Grid container style={{ display: "flex" }}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{ display: "flex" }}
                            >
                              <Typo
                                variant="body4"
                                paddingBottom={3}
                                className={classes.TypoStyle}
                              >
                                Average Time of all Questions
                              </Typo>
                            </Grid>
                            {avgTimeAllQues() &&
                              avgTimeAllQues().map((el) => (
                                <Grid container style={{ display: "flex" }}>
                                  <Grid
                                    item
                                    md={8}
                                    xs={8}
                                    paddingBottom={2}
                                    style={{ display: "flex" }}
                                  >
                                    <Typo
                                      variant="body3"
                                      className={classes.TypoStyle1}
                                    >
                                      {findIcon(el.icon)}
                                      &nbsp;&nbsp;{el.title}&nbsp;&nbsp;
                                      {el.title === "Mean Time" ? (
                                        <Vector />
                                      ) : (
                                        <></>
                                      )}
                                    </Typo>
                                  </Grid>
                                  <Grid
                                    item
                                    md={4}
                                    xs={4}
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typo
                                      variant="body3"
                                      className={classes.TypoStyle2}
                                    >
                                      {el.time}&nbsp;sec
                                    </Typo>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Around1>
                      </Grid>
                      <br></br>

                      <Grid item md={4} xs={12}>
                        <Box
                        // sx={{
                        //   boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
                        //   borderRadius: "12px",
                        //   paddingLeft: 1,
                        // }}
                        >
                          <Grid
                            container
                            // style={{
                            //   display: "flex",
                            //   flexFlow: "column",
                            //   gap: "7px",
                            // }}
                          >
                            <Grid
                              item
                              md={12}
                              xs={12}
                              // style={{
                              //   display: "flex",
                              //   height: 0,
                              //   marginTop: "10px",
                              // }}
                            >
                              <Typo
                                variant="body4"
                                style={{
                                  color: "#052A4E",
                                  fontWeight: "600",
                                  marginLeft: 3,
                                }}
                              >
                                Average Time by Section
                              </Typo>
                            </Grid>

                            <Around>
                              <Grid
                                item
                                md={12}
                                xs={12}
                                style={{ display: "flex" }}
                                id="reasoning"
                              >
                                <TableContainer
                                  style={{
                                    width: "100%",
                                    tableLayout: "fixed",
                                  }}
                                >
                                  <TableBody
                                    sx={{ borderBottom: "none" }}
                                    style={{
                                      borderBottom: "none",
                                      variant: "body3",
                                    }}
                                  >
                                    {avgTimeAllSec() &&
                                      avgTimeAllSec().map((Section) => {
                                        return (
                                          <>
                                            <TableRow
                                              id="table_id"
                                              sx={{ borderBottom: "none" }}
                                              style={{
                                                //  background:"red",
                                                // marginBottom: 10,
                                                display: "flex",
                                                width: "100%",
                                                height: "35px",
                                                variant: "body3",
                                              }}
                                            >
                                              <Grid
                                                md={12}
                                                xs={12}
                                                style={{
                                                  width: "100px",
                                                  borderBottom: "none",
                                                  color: "#354354",
                                                  fontWeight: "400",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {Section.icon}
                                              </Grid>
                                              <Grid
                                                md={6}
                                                xs={6}
                                                style={{
                                                  display: "flex",
                                                  borderBottom: "none",
                                                  paddingLeft: 80,
                                                  paddingRight: 90,
                                                }}
                                                align={"center"}
                                              ></Grid>
                                              <Typo variant="body3">
                                                <Root>
                                                  <Grid
                                                    md={12}
                                                    xs={12}
                                                    style={{
                                                      width: "75px",
                                                      display: "flex",
                                                      borderBottom: "none",
                                                      color: "#354354",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    {`${Section.time}`}mins
                                                  </Grid>
                                                </Root>
                                              </Typo>
                                            </TableRow>
                                          </>
                                        );
                                      })}
                                  </TableBody>
                                </TableContainer>
                              </Grid>
                            </Around>
                          </Grid>
                        </Box>
                      </Grid>
                      <br></br>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        style={{
                          display: "flex",
                          paddingLeft: "10px",
                          paddingRight: "5px",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            display: "flex",
                            boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
                            borderRadius: "12px",
                            background: "#FFFFFF",
                          }}
                        >
                          <Grid
                            item
                            md={7}
                            xs={7}
                            paddingTop={2}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Gap> {scoreData && <PieChart />}</Gap>
                            <Gaping>
                              <Typo
                                variant="body1"
                                style={{
                                  marginTop: "-80px",
                                  fontWeight: "500",
                                  color: "#5B656F",
                                }}
                              >
                                Over all Time Managed
                              </Typo>
                            </Gaping>
                          </Grid>

                          <Grid
                            item
                            md={5}
                            xs={5}
                            paddingLeft={2}
                            paddingTop={2}
                            style={{
                              justifyContent: "right",
                              alignItems: "right",
                            }}
                          >
                            {overAllTimeManaged() &&
                              overAllTimeManaged()
                                .filter(
                                  (el) => el.title !== "Overall time managed"
                                )
                                .map((el) => (
                                  <>
                                    <div style={{ paddingTop: "2rem" }}>
                                      <Typo
                                        variant="body3"
                                        style={{
                                          color: "#354354",
                                          fontWeight: "300",
                                        }}
                                      >
                                        {el.title}
                                      </Typo>
                                    </div>
                                    <div style={{ marginTop: "8px" }}>
                                      {" "}
                                      <Typo
                                        variant="body3"
                                        style={{
                                          color: "#354354",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {`${el.time.toFixed(0)} min`}
                                      </Typo>
                                    </div>

                                    <br></br>
                                  </>
                                ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      padding={3}
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Space>
                        <OutlinedButton onClick={() => setChecked(!checked)}>
                          <b>VIEW LESS</b>
                        </OutlinedButton>
                      </Space>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Collapse>
        </Grid>
      </ContentWrapper>
    </Wrapper>
  );
}
export default SimpleCollapse;
