import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Exit } from "../iconsJS/Exit";
import { Navigate } from "../iconsJS/Navigate";
import { PopupCard } from "../popup/PopupCard";
import OutlinedButton from "../primary-button/OutlinedButton";
import PrimaryButton from "../primary-button/PrimaryButton";
import Typo from "../typo/Typo";
import { Progress } from "./Progress";
import { useStyles } from "./Styles";
import { styled } from "@mui/material/styles";

import "./CustomFooter.css";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@material-ui/core";

export const CustomFooter = (props) => {
  const [open, setOpen] = React.useState(props.Open);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(props.Open);
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <div className={classes.popupModel}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: 15,
              position: !mediaQuery ? "absolute" : "unset",
              bottom: !mediaQuery ? "15px" : "unset",
              padding: !mediaQuery ? "unset" : "30px 0",
            },
          }}
        >
          <PopupCard close={handleClose} endExam={props.endExam} />
        </Dialog>
      </div>
      <div className={classes.footerPart}>
        <Grid container className={classes.footerPadding}>
          <Grid
            item
            md={3}
            xs={6}
            sm={6}
            order={{ xs: 2, sm: 2, md: 1 }}
            marginTop={"15px"}
          >
            <OutlinedButton
              width={!mediaQuery ? "150px" : "200px"}
              style={{ color: "#FFFFFF", fontWeight: "700px" }}
              onClick={handleClickOpen}
            >
              <Exit />
              {props.secondaryButtonText || "END TEST"}
            </OutlinedButton>
          </Grid>
          {props.hasNoProgressBar ? (
            <Grid item md={6} xs={12} sm={12} order={{ xs: 1, sm: 1, md: 2 }} />
          ) : (
            <Grid
              item
              md={6}
              alignItems={"center"}
              alignSelf={"center"}
              xs={12}
              sm={12}
              order={{ xs: 1, sm: 1, md: 2 }}
            >
              <Progress data={props} />
            </Grid>
          )}
          <Grid
            item
            md={3}
            xs={6}
            sm={6}
            container
            justifyContent={"flex-end"}
            order={{ xs: 3, sm: 3, md: 3 }}
            marginTop={"15px"}
          >
            <PrimaryButton
              onClick={props.nextProp}
              disabled={props.disabled}
              width={!mediaQuery ? "150px" : "200px"}
            >
              <b>{props.primaryButtonText || "NEXT"}</b>
              <Navigate />
            </PrimaryButton>
          </Grid>
        </Grid>
      </div>
      {/* <Grid container>
        <div className={classes.footerPart} id="footerorder">
          <div id="one">
            <Grid item md={3} xs={6} sm={6}>
            </Grid>
          </div>

          <div id="two" className={classes.ordertwo}>
            <Grid item md={6} xs={12} sm={12} order={{ xs: 1, sm: 1 }}>
              <div style={{ display: "flex" }}>
                <Progress data={props} />
              </div>
            </Grid>
          </div>

          <Typo></Typo>
          <div id="three">
            <Grid item md={12}>
              <PrimaryButton onClick={props.nextProp}>
                NEXT
                <Navigate />
              </PrimaryButton>
            </Grid>
          </div>
        </div>
      </Grid> */}
    </>
  );
};
