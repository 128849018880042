import Webcam from "react-webcam";
import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { useMediaQuery } from "@mui/material";
import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "../Proctoring/WebcamDemo.css";
// import {  useMediaQuery } from "@mui/material";
import { useStyles } from "./Styles";

const WebcamDemo = () => {
  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame, width, height }) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });
  const classes = useStyles({});

  return (
    <>
      <div
        style={{
          width: "20%",
          height: "20%",
          position: "fixed",
          //  top: "28rem",
          // padding:"2rem",
          display: "flex",
          justifyContent: "space-between",
          right: "2px",
          bottom: "5rem",
          //  left: "65rem",
          // borderRadius: "240px",
        }}
      >
        <div>
          {boundingBox.map((box, index) => (
            <div
              key={`${index + 1}`}
              style={{
                border: "4px solid red",
                position: "absolute",
                top: `${box.yCenter * 60}%`,
                left: `${box.xCenter * 100}%`,
                width: `${box.width * 150}%`,
                height: `${box.height * 110}%`,
                zIndex: 1,
              }}
            />
          ))}
          <Grid
            style={{
              backgroundColor: "red !important",
              borderRadius: "20% !important",
            }}
          >
            <Webcam
              className="webCamAlign"
              ref={webcamRef}
              forceScreenshotSourceSize
              style={{
                height: "70%",
                right: "15%",
                position: "absolute",
                borderRadius: "8%",
              }}
            />
          </Grid>
        </div>
      </div>
      {facesDetected !== 1 ? (
        <Grid container>
          <Grid item md={12} xs={12} sx={12}>
            <Stack id="proctscreen" spacing={2}>
              <div>
                <Alert severity="error" className="alertMsg">
                  Your Face is not detected properly!
                </Alert>
              </div>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default WebcamDemo;
