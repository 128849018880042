import React from "react";
import { Autocomplete } from "@mui/material";
import InputField from "./InputField";

function DropDown({
  id,
  options = [],
  placeholder,
  renderInput,
  renderOption,
  disablePortal,
  disabled,
  hasNoBorder,
  value,
  onChange,
  ...props
}) {
  return (
    <Autocomplete
      id={id}
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      renderOption={renderOption}
      renderInput={(params) => (
        <InputField
          {...params}
          placeholder={placeholder}
          hasNoBorder={hasNoBorder}
        />
      )}
      disablePortal={disablePortal}
      fullWidth
      {...props}
    />
  );
}

export default DropDown;
