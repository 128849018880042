import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { ProctorApp, getStatistics } from "react_proctoring_library";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProctStatus } from "../../redux/slices/Test";
import { getTestQuestionSetById, getSection } from "../../redux/slices/Test";
import { useDispatch, useSelector } from "../../redux/Store";
import { testQuestionSetId } from "../../utils/Constants";
import { Ellipse } from "../iconsJS/Ellipse";
import { IconSymbol } from "../iconsJS/IconSymbol";
import PrimaryButton from "../primary-button/PrimaryButton";
import Typo from "../typo/Typo";
import { Loaders } from "../common-components/Loader";
import { gettingTpr } from "../../redux/slices/Test";
import { checkingTpr } from "../../redux/slices/Test";

import { useStyles } from "./Styles";
import { Container, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@material-ui/core";
import { ContentWrapper, Wrapper } from "../../assets/styles/CommonStyles";
import "./Instruction.css";
import { ChemistryTest } from "../test-section/TestSection";

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    paddingRight: 10,
  },
}));
const Root1 = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),

  [theme.breakpoints.down("md")]: {
    color: "white",
  },
}));
function Test(props) {
  return (
    <div>
      <h1>Proctoring Window</h1>
    </div>
  );
}
export const InstructionPart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [proct, setProct] = useState(false);
  const { questionSetDetails } = useSelector((state) => state.test);
  const [pagestate, setPagestate] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  let [isFullScreen, setFullScreen] = React.useState(false);
  const testIdentifier = "unique-proctoring-identifier";
  const fullScreenMessage =
    "This test can only be completed in Full Screen Mode, do you want to start this test?";
  const getStats = (e) => {
    e.preventDefault();
    console.log(getStatistics(testIdentifier));
  };
  const theme = useTheme();
  useEffect(() => {
    dispatch(
      getTestQuestionSetById((response) => {
        console.log(response.data);

        console.log(response.data, "dddd");
      })
    );
    // dispatch(getTestQuestionSetById());
  }, []);
  useEffect(() => {
    dispatch(
      getProctStatus(testQuestionSetId, (res) => {
        if (res.success) {
          setProct(true);
        } else {
          setProct(false);
        }
      })
    );
  }, []);

  const startingTest = () => {
    if (proct === true) {
      dispatch(
        checkingTpr((response) => {
          console.log(response);

          if (
            response.message === "Take Picture" ||
            response.message === "Picture not saved"
          ) {
            console.log(response.message);

            dispatch(
              gettingTpr((response) => {
                console.log("hiii");
                console.log(response);
                if (response) {
                  console.log(response.message);
                  navigate("/TestProctoring", {
                    state: { isPage: pagestate },
                  });
                }
              })
            );
            console.log("success");
          } else {
            console.log("hi");
            navigate("/TestInstruction", { state: { isPage: pagestate } });
          }
        })
      );
    } else {
      navigate("/TestInstruction", { state: { isPage: pagestate } });
    }
  };

  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles({ mediaQuery });

  const {
    name,
    description,
    durationInMins,
    instructionPoints,
    noOfQuestions,
    noOfSections,
    testInstruction,
  } = questionSetDetails;

  return (
    <>
      {/* {loading  ? <Loaders></Loaders>: */}
      <Wrapper>
        <ContentWrapper>
          <div className={classes.instructionShadow}>
            <Container maxWidth="xl">
              <Grid container>
                <div className={classes.testHead}>
                  <Grid item md={12}>
                    <Typo
                      variant="h4"
                      color="#354354"
                      fontWeight={600}
                      className={classes.testHeaderInstruction}
                    >
                      {name}
                    </Typo>
                  </Grid>
                  <br></br>
                  <Grid item md={12}>
                    {/* {description.map((el) => ( */}
                    <Typo color=" #5B656F" variant="body1">
                      {description}
                    </Typo>
                    {/* ))} */}
                  </Grid>
                </div>
              </Grid>
              <br></br>
              <Card className={classes.cardpart}>
                <Grid container>
                  <Grid item md={3} xs={5} className={classes.cardcontain}>
                    <div>
                      <Typo>
                        Number of<br></br> Questions:
                      </Typo>
                    </div>
                    <div className={classes.innerdivision}>
                      <Typo
                        fontWeight={600}
                        color="#5B656F"
                        variant="h4"
                        className={classes.testInstructionSize}
                      >
                        {noOfQuestions}
                      </Typo>
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2} className={classes.dividerItem}>
                    <div className={classes.dividerstyle}></div>
                  </Grid>
                  <Grid item md={3} xs={5} className={classes.cardcontain}>
                    <div>
                      <Typo>
                        Number of <br></br>Sections:
                      </Typo>
                    </div>
                    <div className={classes.innerdivision}>
                      <Typo
                        fontWeight={600}
                        color="#5B656F"
                        variant="h4"
                        className={classes.testInstructionSize}
                      >
                        {noOfSections}
                      </Typo>
                    </div>
                  </Grid>
                  <Grid item md={1} xs={1} className={classes.dividerItem}>
                    {mediaQuery && <div className={classes.dividerstyle}></div>}
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.cardcontain}>
                    <div className={classes.durationstyle}>
                      <Typo>
                        Test<br></br> Duration:
                      </Typo>
                      <div className={classes.innerdivision}>
                        <Typo
                          fontWeight={600}
                          color="#5B656F"
                          variant="h4"
                          className={classes.testInstructionSize}
                        >
                          {`${durationInMins}`}&nbsp;Minutes
                        </Typo>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <br></br>
              <Grid container>
                <Grid item>
                  <Typo
                    variant="h4"
                    fontWeight={600}
                    color="#354354"
                    paddingTop={4}
                  >
                    Test Instruction
                  </Typo>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typo paddingTop={2} paddingBottom={2} color="#5B656F">
                    {testInstruction}
                  </Typo>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  // id="scrollable"
                  // style={{
                  //   position: "relative",
                  //   height: "250px",
                  //   overflowY: "scroll",
                  //   marginBottom: "2%",
                  //   textAlign: "justify",
                  // }}
                >
                  {instructionPoints.map((el) => (
                    <div className={classes.instruction}>
                      <b>
                        <Ellipse style={{ alignItems: "center" }} />
                      </b>
                      <Typo
                        variant="body1"
                        color="#5B656F"
                        style={{ alignItems: "center" }}
                      >
                        {el}
                        <br />
                        <br></br>
                      </Typo>
                    </div>
                  ))}{" "}
                </Grid>
              </Grid>
            </Container>
          </div>
        </ContentWrapper>
        <Box className={classes.staticButtonContainer}>
          <Box className={"static__button_wrapper"}>
            <Box className={classes.buttonWrapper}>
              <PrimaryButton
                fullWidth={!mediaQuery ? true : false}
                width={!mediaQuery ? "100%" : "220px"}
                onClick={startingTest}
              >
                <b
                  className={classes.getStartedButton}
                  // style={{ position: "relative", left: "20px" }}
                >
                  GET STARTED
                </b>
                <IconSymbol />
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Wrapper>
      {/* } */}
    </>
  );
};
