import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import styled from "styled-components";

export const GreyTick = styled(CheckRoundedIcon)`
  height: 100px !important;
  width: 100px !important;
  color: #f0f0f0 !important;
`;

export const GreenTick = styled(CheckRoundedIcon)`
  height: 100px !important;
  width: 100px !important;
  color: #37e288 !important;
`;
