import React from 'react'

export const CorrectAttempt = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99844 11.2016L1.79844 7.00156L0.398438 8.40156L5.99844 14.0016L17.9984 2.00156L16.5984 0.601562L5.99844 11.2016Z" fill="#00CA99"/>
    </svg>
     
  )
}
