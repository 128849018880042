import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputField from './InputField';

function DatePicker({ value, views, hasNoBorder, disableFuture, placeholder, disabled, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        value={value}
        views={views}
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => (
          <InputField hasNoBorder={hasNoBorder} placeholder={placeholder} {...params} />
        )}
        disableFuture={disableFuture}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
