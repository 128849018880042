import React from 'react'
import { Box, Container } from '@mui/material'
import Typo from '../typo/Typo'

export const SigninPeople = () => {
  return (
    <>
    
    
    <svg width="48" height="48"  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#DDE9FF"/>
    <path d="M23.9987 23.9987C26.9454 23.9987 29.332 21.612 29.332 18.6654C29.332 15.7187 26.9454 13.332 23.9987 13.332C21.052 13.332 18.6654 15.7187 18.6654 18.6654C18.6654 21.612 21.052 23.9987 23.9987 23.9987ZM23.9987 26.6654C20.4387 26.6654 13.332 28.452 13.332 31.9987V34.6654H34.6654V31.9987C34.6654 28.452 27.5587 26.6654 23.9987 26.6654Z" fill="#5842F6"/>
    </svg>
    
  
   
     
     
     
    
     </>
    
    
  )
}
