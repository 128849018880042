import Grid from "@mui/material/Grid";
import React from "react";
import { CorrectEllipse } from "../iconsJS/CorrectEllipse";
import { DoughChart } from "../iconsJS/DoughChart";
import { IncorrectEllipse } from "../iconsJS/IncorrectEllipse";
import { MissedEllipse } from "../iconsJS/MissedEllipse";
import Typo from "../typo/Typo";
import { useStyles } from "./Styles";
import Box from "@mui/material/Box";
import PrimaryButton from "../primary-button/PrimaryButton";
import { Navigate } from "../iconsJS/Navigate";
import { styled } from "@mui/material/styles";
import { Donut } from "../chart/Donut";
import { useSelector } from "../../redux/Store";
import ApexCharts from "apexcharts";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ReviewsBar from "./ReviewsBar";
import { useState } from "react";
import "../../components/result-part/DonutChartAlign.css";
export const ResultPart = (props) => {
  const classes = useStyles();
  //const showdata =  scoreData?.insights?.donut?.percentage;
  //console.log(showdata);

  const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),

    [theme.breakpoints.up("lg")]: {
      marginLeft: "-21px",
    },
  }));
  const Space1 = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),

    [theme.breakpoints.only("xs")]: {
      marginRight: "105px",
    },
  }));
  const Space = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-21px",
    },
  }));
  const Typomin = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {},
  }));

  const [reviewScore, setReviewScore] = useState(10);
  const { scoreData } = useSelector((state) => state.test);
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));
  const secPercentage = () => {
    if (scoreData) {
      return scoreData?.insights?.cards?.find(
        (el) => el.title === "Sectionwise Percentage"
      ).events;
    } else return null;
  };

  return (
    <>
      <Grid container className={classes.GridStyle}>
        <Grid item md={12} xs={12}>
          <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12} md={6} className={classes.GridItemStyle}>
              <Grid container p={2}>
                <Grid
                  iem
                  md={5}
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {console.log(scoreData?.insights?.donut?.percentage)}
                  <Donut />
                </Grid>
                <Grid
                  item
                  md={7}
                  xs={6}
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  id="overallContainer"
                >
                  <Typomin>
                    <Typo
                      variant="h5"
                      className={classes.TypoStyle1}
                      id="overallText"
                    >
                      Overall Score
                    </Typo>
                  </Typomin>
                  <Typo
                    variant="h5"
                    className={classes.TypoStyle2}
                    id="overallAlign"
                  >
                    <b id="overallAlign">
                      {" "}
                      {`${scoreData?.insights?.donut?.score.toFixed(
                        2
                      )} /${scoreData?.insights?.donut?.totalmarks.toFixed(2)}`}
                    </b>{" "}
                  </Typo>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  gap: mediaQuery ? "0rem" : "1.5rem",
                }}
              >
                {secPercentage() &&
                  secPercentage().map((el) => (
                    <>
                      {console.log(el)}

                      <Grid item md={5} xs={3} s>
                        <ReviewsBar id="donutPart" score={el.sectionPercent} />

                        <p
                          className={classes.donutAlign}
                          id="donutWordAlign"
                          style={{
                            display: mediaQuery ? "flex" : "",
                            justifyContent: "center",
                            padding: "1rem",
                            color: "#686868",
                            fontWeight: 700,
                            paddingLeft: "0rem",
                          }}
                        >
                          {el.SectionName}
                        </p>
                      </Grid>
                    </>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
