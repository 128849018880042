import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  colorPrimary: {
    backgroundColor: "#00695C",
  },
  barColorPrimary: {
    backgroundColor: "#B2DFDB",
  },
  ProgressLine: {
    background: "red",
  },
  // cssMuiLinearProgressbar1: {
  //   background: "linear-gradient(91deg, #22D1EE 4.75%, #03E2E2 99.26%)",
  // },
}));
