import { saveAs } from "file-saver";
import CustomizedSnackbars from "../snack/snack";
import { useReactMediaRecorder } from "react-media-recorder";
import Timer from "./VideoTimer";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  CustomOutlinedButton,
  CustomRetryButton,
} from "../primary-button/CustomButtonStyle";
import { CustomPrimaryButton } from "../primary-button/CustomButtonStyle";
import React, { useEffect, useState, useRef } from "react";
import Typo from "../typo/Typo";
import "./video.css";
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS,
} from "react-record-webcam";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { dispatch } from "../../redux/Store";
import { styled } from "@mui/material/styles";
import { uploadVideo } from "../../redux/slices/Test";
import LinearDeterminate from "../ProgressBar/Progress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OPTIONS = {
  filename: "test-filename",
  fileType: "mp4",
  width: "1000px",
  height: "1200px",
  quality: 2.0,
};

const RecordView = () => {
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: true,
      facingMode: { exact: "environment" },
    });

  return (
    <div>
      <p>{status}</p>
      <video src={mediaBlobUrl} controls autoPlay loop />
      <button onClick={startRecording}>Start Recording</button>
      <button onClick={stopRecording}>Stop Recording</button>
    </div>
  );
};

export default function Video({
  setFilePath,
  filePath,
  VideoRecordingResponse,
  VideoRecordingStop,
}) {
  useEffect(() => {
    // <Stack id="proctscreen" spacing={2}>
    //   <div>
    //     <Alert severity="error" className="alertMsg">
    //       You can record a video upto 1 Minute!!
    //     </Alert>
    //   </div>
    // </Stack>;

    if (!filePath) {
      setIsProgress(false);
      recordWebcam.retake();
      setProgress(0);
      setIsSubmit(false);
      setIsActive(false);
      setIsPaused(false);
      clearInterval(increment.current);
      setIsActive(false);
      setIsPaused(false);
      setTimer(0);
    }
  }, [filePath]);

  const formatTime = () => {
    const getSeconds = `0${timer % 120}`.slice(-2);
    console.log(getSeconds);
    console.log(timer);
    if(timer >120)
    {
      setTimer(0); 
    }

    return ` ${timer}s`;
  };

  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment = useRef(null);
  const [open, setOpen] = React.useState(false);

  const recordWebcam = useRecordWebcam(OPTIONS);
  const [finalFile, setFinalFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isProgress, setIsProgress] = useState(false);
  const [retryDisable, setRetryDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const getSeconds = `0${timer % 120}`.slice(-2);
  console.log(timer);
  console.log(getSeconds);
  const [time, setTime] = useState(null);

  const handleRetake = () => {
    recordWebcam.retake();

    clearInterval(increment.current);

    setIsActive(false);

    setIsPaused(false);

    setTimer(0);
  };

  const getRecordingFileHooks = async () => {
    console.log("dddd");
    setIsSubmit(true);
    setSubmit(true);
    VideoRecordingStop(true);
    setRetryDisable(true);
    var name = "recordedFile";
    var indexOf;
    var file = await recordWebcam.getRecording();

    var indexOf = file.type.indexOf("/");

    var newFileType = file.type.substr(indexOf + 1);

    var blob = new Blob([file], { type: "video/mp4" });
    let videoURL = window.URL.createObjectURL(blob);
    const blobF = await fetch(videoURL).then((res) => res.blob());

    var newFile = new File(
      [blob],
      name
        .concat(".", newFileType)
        .replace("webm;codecs=vp8,opus", "mp4")
        .replace("x-matroska;codecs=avc1,opus", "mp4"),
      { type: "video/mp4" }
    );
    setFinalFile(newFile);

    const fileData = new FormData();
    fileData.append("file", newFile);

    if (timer < 60) {
      console.log(getSeconds);
      setOpen(true);
      handleRetake();
    } else {
      const onUploadProgress = (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );
        console.log("onUploadProgress", totalLength);
        if (totalLength !== null) {
          setIsProgress(true);
          setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
        }
      };

      dispatch(
        uploadVideo(fileData, onUploadProgress, (res) => {
          if (res.success) {
            setMessage(null);

            if (setFilePath) setFilePath(res.data);
          } else {
            setMessage(res.message);
            setIsProgress(false);
            setProgress(0);
          }
          console.log(res);
          VideoRecordingResponse(res);
        })
      );
    }
  };

  const getRecordingFileRenderProp = async (blob) => {
    console.log({ blob });
  };

  useEffect(() => {
    recordWebcam.open();
  }, []);
  console.log(progress);

  const handleStop = () => {
    recordWebcam.stop();
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleStart = () => {
    setSubmit(false);
    recordWebcam.start();
    setIsActive(true);
    setIsPaused(true);
    setRetryDisable(false);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const playVideo = () => {
    let current = recordWebcam.previewRef;
    console.log(current);

    recordWebcam.previewRef.current.play();
  };

  const AnswerVideo = styled("div")(({ theme }) => ({
    paddingRight: theme.spacing(3.75),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gap: "1rem",
    },
  }));
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Grid container spacing={0}>
      <Grid>
        <div>
          {isProgress && (
            <>
              <LinearDeterminate value={progress} className="BigProgressLine" />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typo variant="h5" color="#32CD32" className="BigProgressBar">
                  {progress === 100 && "Upload successful"}
                </Typo>
              </div>{" "}
            </>
          )}
          {Boolean(message) && (
            <>
              <b style={{ color: "#DF2D2D" }} className="ErrorMsgLine">
                {message}
              </b>
              <CustomPrimaryButton
                className="TryAgainButton"
                onClick={getRecordingFileHooks}
                style={{
                  position: "relative",
                  display: "flex",
                  left: "136px",
                  marginTop: "20px",
                }}
              >
                &nbsp; <b> {"Try again"}</b> &nbsp;
              </CustomPrimaryButton>
            </>
          )}
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                color: "maroon",
                top: "105px",
                left: "50px",
              }}
            >
              <b>{formatTime()}</b>
            </p>
            &nbsp;&nbsp;
            {!isActive && !isPaused ? (
              <CustomPrimaryButton
                className="startButtonAlign"
                variant="h2"
                style={{
                  display: `${
                    recordWebcam.status === CAMERA_STATUS.OPEN ? "flex" : "none"
                  }`,
                  marginBottom: "15px",
                }}
                disabled={
                  recordWebcam.status === CAMERA_STATUS.RECORDING ||
                  recordWebcam.status === CAMERA_STATUS.PREVIEW
                }
                onClick={handleStart}
              >
                &nbsp; <b> START</b> &nbsp;
              </CustomPrimaryButton>
            ) : isPaused ? (
              <CustomPrimaryButton
                className="startButtonAlign"
                variant="h2"
                style={{
                  display: `${
                    recordWebcam.status === CAMERA_STATUS.RECORDING
                      ? "flex"
                      : "none"
                  }`,
                  marginBottom: "15px",
                }}
                disabled={recordWebcam.status !== CAMERA_STATUS.RECORDING}
                onClick={handleStop}
              >
                {console.log(recordWebcam.status)}
                &nbsp; <b> Stop</b> &nbsp;
              </CustomPrimaryButton>
            ) : (
              <>
                &nbsp;
                <AnswerVideo>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      position: "relative",
                      right: mediaQuery ? "90px" : "-13px",
                    }}
                  >
                    <CustomPrimaryButton
                      disabled={submit}
                      onClick={getRecordingFileHooks}
                      style={{
                        display: `${
                          recordWebcam.status === CAMERA_STATUS.PREVIEW
                            ? "flex"
                            : "none"
                        }`,
                      }}
                    >
                      {console.log(recordWebcam.status)}
                      &nbsp; <b> Submit</b> &nbsp;
                    </CustomPrimaryButton>

                    <CustomPrimaryButton
                      className="PlayButton"
                      disabled={submit}
                      onClick={playVideo}
                      style={{
                        display: `${
                          recordWebcam.status === CAMERA_STATUS.PREVIEW
                            ? "flex"
                            : "none"
                        }`,
                        width: "100px",
                        position: "relative",
                        right: "30px",
                      }}
                    >
                      {console.log(recordWebcam.status)}
                      &nbsp;{" "}
                      <b>
                        {/* {recordWebcam.previewRef.current.pause() ? 'Play' : 'Pause'} */}
                        Play
                      </b>{" "}
                      &nbsp;
                    </CustomPrimaryButton>

                    <CustomRetryButton
                      className="RetryButton"
                      // disabled={retryDisable}
                      disabled={submit}
                      // disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                      onClick={handleRetake}
                      // {recordWebcam.retake}
                      style={{
                        display: `${
                          recordWebcam.status === CAMERA_STATUS.PREVIEW
                            ? "flex"
                            : "none"
                        }`,
                        position: "relative",
                        right: "60px",
                      }}
                    >
                      {console.log(recordWebcam.status)}
                      &nbsp; <b> Retry</b> &nbsp;
                    </CustomRetryButton>
                  </div>
                </AnswerVideo>
                {/* )
        } */}
              </>
            )}
            <video
              id="video-style"
              ref={recordWebcam.webcamRef}
              style={{
                display: `${
                  recordWebcam.status === CAMERA_STATUS.OPEN ||
                  recordWebcam.status === CAMERA_STATUS.RECORDING
                    ? "block"
                    : "none"
                }`,
                width: "60%",
                height: "85%",
              }}
              autoPlay
              muted
            />
            {/* </AnswerVideo> */}
            <video
              id="video-style"
              // ref={video}
              ref={recordWebcam.previewRef}
              //  autoPlay
              style={{
                display: `${
                  recordWebcam.status === CAMERA_STATUS.PREVIEW
                    ? "flex"
                    : "none"
                }`,
                width: "60%",
                height: "85%",
                //  borderRadius:"2px",
              }}
              controls
            />
            {/* {isProgress && (
          <>
            <LinearDeterminate value={progress} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typo variant="h5" color="#32CD32">
                {progress === 100 && "Upload successful"}
              </Typo>
            </div>{" "}
          </>
        )}
        {Boolean(message) && (
          <>
            <b>{message}</b>
            <CustomPrimaryButton onClick={getRecordingFileHooks}>
              &nbsp; <b> {"Try again"}</b> &nbsp;
            </CustomPrimaryButton>
          </>
        )} */}
          </div>
          <CustomizedSnackbars
            // style={{display:"flex",alignSelf:"center",marginLeft:300}}
            open={open}
            handleClose={() => setOpen(false)}
            type={"error"}
            text="Record atleast 1 min"
          />
        </div>
      </Grid>
    </Grid>
  );
}
