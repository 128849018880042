import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Pie } from "react-chartjs-2";
import { useSelector } from "../../redux/Store";

ChartJS.register(ArcElement, Tooltip);

export default function PieChart() {
  const { scoreData } = useSelector((state) => state.test);
  const { insights } = scoreData;

  const getValues = () => {
    return insights.cards.find((el) => el.title === "Overall Time").events;
  };

  const data = {
    labels: ["", ""],
    datasets: [
      {
        data: getValues()
          .filter((el) => el.title !== "Overall time managed")
          .map((el) => el.time),
        backgroundColor: ["#ADF2C8", "#E1FFED"],
        borderColor: ["#ADF2C8", "#E1FFED"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    circumference: 180,
    plugins: {
      datalabels: {
        align: "center",
        font: {
          size: "26px",
          weight: "bold",
        },
        rotation: 90,
        color: "#052A4E",
        formatter: (value, context) => {
          const percentageValue = getValues().filter(
            (el) => el.title === "Overall time managed"
          )[0]?.time;
          return context.dataIndex === 0
            ? `${percentageValue ? percentageValue.toFixed(2) : 0}%`
            : null;
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
            };
          },
        },
      },
    },
  };
  return (
    <Pie
      options={options}
      style={{ transform: "rotate(270deg)", marginTop: "-45px" }}
      data={data}
      plugins={[ChartDataLabels]}
    />
  );
}
