import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  questCard: {
    padding: 10,
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2) !important",
    
    borderRadius: 12,
  },
  questCard: {
    color: "#2D6CDF",
    boxShadow: " 4px 4px 16px rgba(146, 170, 212, 0.2) !important",
  },
  question: {
    paddingTop: 7,
  },

  questHead: {
    "& figure": {
      width: "100% !important",
      overflow: "hidden",
    },
    "& img": {
      width: "auto !important",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
}));
