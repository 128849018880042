import { Grid } from '@mui/material';
import React from 'react';
import DatePicker from '../common-components/DatePicker';
import InputField from '../common-components/InputField';
import DropDown from '../common-components/DropDown';
import { CustomTypo, InputBox, useStyles, ErrorMsg } from './Styles';
import { isAlphabetOnly, isNumber, isNumberWithDot } from '../../utils/Validation';

function PgInfo({
  pgDegree,
  pgDepartment,
  pgSemester,
  pgPercentage,
  pgEndDate,
  pgCollege,
  handleChange,
  pgPassingYearPicker,
  getFormDetails,
  errorMsg,
  allState,
}) {
  const classes = useStyles();
  const exceptThisSymbols = ['e', 'E', '+', '-'];

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12}>
        <CustomTypo variant={'body2'} className={classes.sectionText}>
          {'Section-2'}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <CustomTypo variant={'h6'} color={'#354354'} fontWeight={600}>
          {'Post Graduation Information (If any)'}
        </CustomTypo>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2.75} pb={2.75}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG Degree'}
                </CustomTypo>
                <InputBox style={{ marginBottom: '20px' }}>
                  <InputField
                    autoComplete='off'
                    value={pgDegree}
                    onChange={(evt) => {
                      if (isAlphabetOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    placeholder={'Enter PG Degree'}
                    name='pgDegree'
                    disabled={getFormDetails?.pgDegree ? true : false}
                    hasNoBorder
                  />
                </InputBox>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG Branch'}
                </CustomTypo>
                <InputBox>
                  <InputField
                    autoComplete='off'
                    value={pgDepartment}
                    onChange={(evt) => {
                      if (isAlphabetOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    placeholder={'Enter PG Branch'}
                    name='pgDepartment'
                    disabled={getFormDetails?.pgDepartment ? true : false}
                    hasNoBorder
                  />
                </InputBox>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG Semester'}
                </CustomTypo>
                <InputBox>
                  <InputField
                    autoComplete='off'
                    type={'number'}
                    placeholder={'Enter PG Semester'}
                    value={pgSemester}
                    onChange={(evt) => {
                      if (parseInt(evt.target.value) > 12 || parseInt(evt.target.value) === 0) {
                        evt.preventDefault();
                      } else {
                        handleChange(evt);
                      }
                    }}
                    inputProps={{ min: 1, max: 12 }}
                    name={'pgSemester'}
                    onKeyPress={(evt) => {
                      if (isNumber(evt) || evt.target.value.length > 1) {
                        evt.preventDefault();
                      }
                    }}
                    disabled={getFormDetails?.pgSemester ? true : false}
                    hasNoBorder
                  />
                </InputBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2.75}>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG % (out of 100)'}
                </CustomTypo>
                <InputBox>
                  <InputField
                    type={'number'}
                    autoComplete='off'
                    name={'pgPercentage'}
                    value={pgPercentage}
                    placeholder={'Enter PG Percentage'}
                    onChange={(evt) => {
                      if (evt.target.value > 100 || evt.target.value === '0') {
                        evt.preventDefault();
                      } else {
                        handleChange(evt);
                      }
                    }}
                    disabled={getFormDetails?.pgPercentage ? true : false}
                    onKeyDown={(evt) => {
                      let num = evt.target.value;
                      if (
                        (num.length === 0 && evt.which === 190) ||
                        exceptThisSymbols.includes(evt.key)
                      ) {
                        evt.preventDefault();
                      }
                    }}
                    inputProps={{ min: 1, max: 100 }}
                    hasNoBorder
                  />
                </InputBox>
                <CustomTypo
                  color={'#828D98'}
                  fontWeight={500}
                  fontSize={10}
                  paddingTop={'7px'}
                  paddingLeft={'5px'}
                >
                  {'Convert CGPA in % as per your university conversion scale.'}
                </CustomTypo>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG Year of Passing'}
                </CustomTypo>
                <InputBox>
                  <DatePicker
                    value={pgEndDate}
                    views={['year', 'month']}
                    placeholder={'Enter PG Year of Passing'}
                    onChange={(e) => pgPassingYearPicker(e)}
                    hasNoBorder
                    name={'pgEndDate'}
                    // disableFuture
                    disabled={getFormDetails?.pgEndDate ? true : false}
                  />
                </InputBox>
              </Grid>
              <Grid item xs={12}>
                <CustomTypo color={'#828D98'} variant={'caption'} fontWeight={500}>
                  {'PG College Name'}
                </CustomTypo>
                <InputBox>
                  <InputField
                    autoComplete='off'
                    value={pgCollege}
                    onChange={(evt) => {
                      if (isAlphabetOnly(evt)) {
                        handleChange(evt);
                      } else {
                        evt.preventDefault();
                      }
                    }}
                    placeholder={'Enter PG College Name'}
                    name='pgCollege'
                    disabled={getFormDetails?.pgCollege ? true : false}
                    hasNoBorder
                  />
                </InputBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PgInfo;
