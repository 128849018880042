import { Badge, Box, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Circle } from "rc-progress";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GreenTick, GreyTick } from "../../assets/css/styledComponent";
import Typo from "../typo/Typo";
import { useStyles } from "./Style";

export const ConfirmPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const params = useParams();

  useEffect(() => {
    if (progress !== 100) {
      setTimeout(() => {
        setProgress(progress + 10);
      }, 800);
    } else {
      navigate(`/Result/${params.testExecutionId}`);
    }
  }, [progress]);

  const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",

      display: "flex",

      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingLeft: 30,
    },
  }));
  const Confirm = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),

    [theme.breakpoints.down("md")]: {
      paddingTop: "40%",
    },
  }));
  const Complete = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),

    [theme.breakpoints.only("xs")]: {
      display: "flex",
      variant: "body1",
      fontSize: "11px",
      alignItems: "center",
      padding: 1,
      paddingRight: 10,
      marginLeft: 35,

      justifyContent: "center",
    },
  }));
  const Complete1 = styled("div")(({ theme }) => ({
    padding: theme.spacing(0),

    [theme.breakpoints.only("xs")]: {
      display: "flex",
      variant: "body1",
      fontSize: "12px",

      justifyContent: "center",
      marginRight: 1,
      paddingRight: 40,
      fontSize: "12px",
    },
  }));

  // [theme.breakpoints.up('md')]: {
  //   backgroundColor: "blue",
  // },

  return (
    <>
      <Confirm>
        <div className={classes.confirmBlock}>
          <Container maxWidth="sm">
            <Grid container>
              <Grid item md={12} xs={12} className={classes.confirmContainer}>
                {/* <ThemeProvider theme={dotTheme}> */}
                <Badge
                  // badgeContent={<img src={Dot} alt="" />}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Circle
                    style={{
                      width: "277",
                      height: "277",
                      padding: 30,
                      // transform: "rotate(137deg)",
                    }}
                    percent={progress}
                    trailWidth="5"
                    strokeWidth="5"
                    strokeColor="#37E288"
                    // gapDegree="20"
                  />

                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {progress === 100 ? <GreenTick /> : <GreyTick />}
                  </Box>
                </Badge>
                {/* </ThemeProvider> */}
              </Grid>

              <Grid
                item
                md={12}
                xs={10}
                sm={12}
                className={classes.confirmDescription}
                // container
                // justifyContent={"center"}
                // alignItems={"center"}
                // style={{ marginTop: 20 }}
              >
                <Root>
                  <Typo
                    align={"center"}
                    variant="h5"
                    color={"#354354"}
                    fontWeight={700}
                  >
                    <Complete>
                      {" "}
                      {params.testName} Succesfully Completed
                    </Complete>
                  </Typo>
                </Root>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                sm={12}
                variant="body2"
                // style={{ marginTop: 20 }}
                // fontSize={22}
                fontWeight={400}
                color="#5B656F"
                className={classes.confirmDescription}
              >
                <Root>
                  <Typo>
                    <br></br>
                    <Complete1>You'll receive communication shortly</Complete1>
                  </Typo>
                </Root>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Confirm>
    </>
  );
};
