import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from '../../redux/Store';

export function Donut() {
  const { scoreData } = useSelector((state) => state.test);
  const { score, missed, incorrect, percentage, totalmarks, correct } = scoreData.insights.donut;

  const data = {
    labels: ['Correct', 'Missed', 'InCorrect'],
    datasets: [
      {
        // data: [score, missed, incorrect, percentage, totalmarks, correct],
        data: [correct, missed, incorrect],
        backgroundColor: ['#FF7284', '#5842F6', '#DDE9FF'],
        borderColor: ['#FF7284', '#5842F6', '#DDE9FF'],
        borderWidth: 1,
      },
    ],
    hoverOffset: 4,
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 120).toFixed(2);
        ctx.font = '600 ' + fontSize + "em 'Poppins'";
        ctx.textBaseline = 'middle';
        var text = `${percentage?.toFixed(0) || 0}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = (height + 5) / 2;
        ctx.fillStyle = 'rgba(88, 66, 246, 1)';
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return <Doughnut data={data} plugins={plugins} />;
}
