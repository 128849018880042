import { makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme) => ({
  GridStyle: {
    display: "flex",
    // background: "#FFFFFF",

    // boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.2)",
    // borderRadius: "12px",
  },
  GridItemStyle: {
    // background: "#FFFFFF",
    display: "flex",

    // boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    // borderRadius: "12px",
  },
  DoughStyle: {
    alignItems: "center",
    justifyContent: "center",
  },
  TypoStyle1: {
    color: "#5B656F",
    fontWeight: "400",
    alignItems: "center",
    display: "flex",
  },
  TypoStyle2: {
    color: "#5B656F",
    fontWeight: "600",
    alignItems: "center",
    display: "flex",
  },
  GridItemStyle1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  Container1: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // height: "100%",
  },
  GridItem2: {
    // background: "#FFFFFF",
    // boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    // borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  GridItem3: {
    // background: "#FFFFFF",
    // boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    // borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  GridItem4: {
    // background: "#FFFFFF",
    // boxShadow: "4px 4px 16px rgba(146, 170, 212, 0.1)",
    // borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  idContainer: {
    // width: "40%",
  },
  // TypoStyle3: {
  //   color: "#686868",

  // },
  // "@media(max-width:600px)": {
  //   donutAlign: {
  //     background: "red",
  //     position: "relative !important",
  //     left: "30px !important",
  //   },
  // },
  "@media (max-width:600px)": {
    TypoStyle2: {
      color: "#5B656F",
      fontWeight: "600",
      alignItems: "center",
      display: "flex",
    },
  },
}));
